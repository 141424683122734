import styled from "styled-components";

export const Grid = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 100%;
    grid-column-gap: 0px;
    grid-template-columns: auto auto 1fr;
    padding-top: 80px;

    @media (min-width: 1000px) {
        padding-top: 0;
    }

    @media (min-width: 800px) {
        overflow-y: hidden;
    }

    @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
        grid-template-rows: 65px calc(100vh - 65px);
    }
`;
