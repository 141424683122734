import { isAfter } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CurrentServerContext } from "../../../shared/components/CurrentServerProvider";
import { showApiError } from "../../../shared/helpers/showToast";
import { useActiveVouchersQuery } from "../../Billings/api";
import { BillingService } from "../../Billings/types";
import useUserData from "../../User/hooks/useUserData";
import { GameStatus } from "../../User/types";
import {
    useDisableAutostartAndStopMutation,
    useEnableAutostartMutation,
    useStartServerMutation,
    useStopServerMutation,
} from "../api";
import isServicePaidFor from "../helpers/isServerPaidFor";
import { useWalletLogsQuery, useWalletTransactionsQuery } from "../modules/Wallets/api";

export default function () {
    const { server, service } = useContext(CurrentServerContext);
    const [memoryUsed] = useState(0);

    const [isRunning, setIsRunning] = useState(false);
    const [isAutostartEnabled, setIsAutostartEnabled] = useState(false);
    const [isServerChangingState, setIsServerChangingState] = useState(false);
    const { status } = useContext(CurrentServerContext);
    const gameStatus = useMemo(() => {
        if (status.error || status.loading) {
            return null;
        }

        return status.data.game_status;
    }, [status]);
    const { user } = useUserData();
    const isServerOwner = useMemo(() => {
        if (service && !user.services.some((s: BillingService) => s.service_id === service.service_id)) {
            return false;
        }
        return true;
    }, [service, user.services]);

    const { data: walletLogs, isLoading: walletLogsLoading } = useWalletLogsQuery(service?.wallet_id, {
        skip: !service,
    });
    const { data: walletTransactions, isLoading: walletTransactionsLoading } = useWalletTransactionsQuery(
        service?.wallet_id,
        {
            skip: !service,
        }
    );
    const { data: activeVouchers, isLoading: activeVouchersLoading } = useActiveVouchersQuery(server?.id, {
        skip: !server,
    });
    const [enableAutostartRequest, { isLoading: isEnableAutostartLoading }] = useEnableAutostartMutation();
    const [disableAutostartAndStopRequest, { isLoading: isDisableAutostartLoading }] =
        useDisableAutostartAndStopMutation();
    const [startServerRequest] = useStartServerMutation();
    const [stopServerRequest] = useStopServerMutation();

    const refreshServerData = () => {
        // dispatch(Actions.serverCurrentRequest(serverId, navigate));
        // dispatch(Actions.activeVouchersRequest(serverId));
        // dispatch(Actions.serverQuotaRequest(serverId));
        // dispatch(Actions.serverPaymentsRequest(serverId));
    };

    useEffect(() => {
        if (!server || !service) {
            return;
        }
        setIsAutostartEnabled(server.autostart && !!isServicePaidFor(service));
        setIsRunning(
            !!gameStatus &&
                [
                    GameStatus.IRL_RUNNING,
                    GameStatus.IRL_IDLE,
                    GameStatus.IRL_NEW,
                    GameStatus.IRL_LIGHT_SLEEP,
                    GameStatus.IRL_DEEP_SLEEP,
                    GameStatus.IRL_HIBERNATED,
                ].includes(gameStatus)
        );
    }, [server, service, gameStatus]);

    const enableAutostart = useCallback(
        async (hardRestart = false) => {
            if (!server) {
                return false;
            }
            setIsServerChangingState(true);
            try {
                await enableAutostartRequest({ serverId: server.id, hardRestart }).unwrap();

                setIsServerChangingState(false);
                setIsRunning(true);
                return true;
            } catch (e) {
                showApiError(e);
                setIsServerChangingState(false);
                return false;
            }
        },

        [enableAutostartRequest, server]
    );

    const disableAutostartAndStop = useCallback(
        async (hardRestart = false) => {
            if (!server) {
                return false;
            }
            setIsServerChangingState(true);
            try {
                await disableAutostartAndStopRequest({
                    serverId: server.id,
                    hardRestart,
                }).unwrap();

                setIsRunning(false);
                setIsServerChangingState(false);
                return true;
            } catch (e) {
                showApiError(e);
                setIsServerChangingState(false);
                return false;
            }
        },
        [disableAutostartAndStopRequest, server]
    );

    const startServer = useCallback(async () => {
        setIsServerChangingState(true);
        try {
            await startServerRequest(server.id).unwrap();
            setIsRunning(true);
        } catch (e) {
            showApiError(e);
        }
        setIsServerChangingState(false);
    }, [server, startServerRequest]);

    const stopServer = useCallback(async () => {
        setIsServerChangingState(true);
        try {
            await stopServerRequest(server.id).unwrap();
            setIsRunning(false);
        } catch (e) {
            showApiError(e);
        }
        setIsServerChangingState(false);
    }, [server, stopServerRequest]);

    const restartServer = useCallback(() => {
        // TODO: restart
    }, []);

    const walletData = useMemo(() => {
        return {
            logs: walletLogs || [],
            transactions: walletTransactions || [],
        };
    }, [walletLogs, walletTransactions]);

    const isExpired =
        useMemo(() => service && service.expires && isAfter(new Date(), new Date(service.expires)), [service]) || false;

    return {
        isAutostartEnabled,
        refreshServerData,
        memoryUsed,
        isRunning,
        startServer,
        stopServer,
        autostartLoading: isEnableAutostartLoading || isDisableAutostartLoading,
        restartServer,
        walletDataLoading: walletLogsLoading || walletTransactionsLoading,
        walletData,
        activeVouchers,
        activeVouchersLoading,
        enableAutostart,
        disableAutostartAndStop,
        isServerOwner,
        isExpired,
        isServerChangingState,
    };
}
