import { useTranslation } from "react-i18next";
import { useGenerateVoucherMutation } from "../../modules/Admin/api";
import CreateVoucherForm from "../../modules/Admin/components/CreateVoucherForm";
import { CreateNewVoucherFormValues, CreateNewVoucherPayload } from "../../modules/Admin/types";
import Layout from "../../shared/components/Layout";
import LayoutSpace from "../../shared/components/LayoutSpace";
import showToast, { showApiError } from "../../shared/helpers/showToast";
import usePermissions from "../../shared/hooks/usePermissions";
import NotFoundScreen from "../NotFoundScreen";

const CreateVoucherScreen = () => {
    const [mutate] = useGenerateVoucherMutation();
    const { t } = useTranslation();
    const { canCreateVoucher } = usePermissions();

    const handleSubmit = async (values: CreateNewVoucherFormValues, resetForm: () => void) => {
        const transformedValues: CreateNewVoucherPayload = {
            length: values.length,
            charset: values.charset,
            prefix: values.prefix || undefined,
            expires: values.expires ? new Date(values.expires).toISOString() : undefined,
            group_id: values.group_id || undefined,
            uses: values.uses || undefined,
            per_user: values.per_user || undefined,
            actions: values.actions.map(({ wallet_tx, ...action }) => ({
                ...action,
                coupon: action.coupon
                    ? {
                          ...action.coupon,
                          expires:
                              action.coupon && action.coupon.end_time
                                  ? new Date(action.coupon.end_time).toISOString()
                                  : undefined,
                      }
                    : undefined,
                wallet_tx: wallet_tx ? Object.fromEntries(wallet_tx.map((tx) => [tx.currency, tx])) : undefined,
            })),
        };

        try {
            await mutate(transformedValues).unwrap();
            showToast({ text: t("create_new_voucher.success"), variant: "success" });
            resetForm();
        } catch {
            showApiError(t("create_new_voucher.error"));
        }
    };

    if (!canCreateVoucher) {
        return <NotFoundScreen />;
    }

    return (
        <Layout>
            <LayoutSpace
                style={{
                    alignItems: "center",
                }}
            >
                <CreateVoucherForm onSubmit={handleSubmit} />
            </LayoutSpace>
        </Layout>
    );
};

export default CreateVoucherScreen;
