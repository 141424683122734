import { toast } from "react-toastify";
import { ErrorsMessages, ResourceError, ResourceErrorMapping } from "../../constants";
import { ErrorCode } from "../../rootTypes";
import CustomError, { ErrorFields } from "../CustomError";

interface Settings {
    variant: "success" | "warning" | "error" | "default" | "info";
    text: string;
    desc?: string;
}

const showToast = ({ variant, text, desc }: Settings) => {
    toast(`${text}\n${desc || ""}`, {
        type: variant,
        position: toast.POSITION.TOP_LEFT,
    });
};

export const showRequestErrorToast = (errorCode?: string, event_id?: string, fields?: ErrorFields) => {
    let text;

    if (
        [ErrorCode.RESOURCE_NOT_FOUND, ErrorCode.RESOURCE_ALREADY_EXISTS, ErrorCode.TOO_MANY_REQUESTS].includes(
            errorCode as ErrorCode
        ) &&
        fields &&
        fields.resource
    ) {
        const resourceError =
            ResourceErrorMapping[fields.resource as ResourceError] || ErrorsMessages[ErrorCode.RESOURCE_NOT_FOUND];
        text = resourceError;
    } else {
        text = errorCode
            ? ErrorsMessages[errorCode as ErrorCode] || ErrorsMessages[ErrorCode.NOT_RECOGNIZED]
            : ErrorsMessages[ErrorCode.NOT_RECOGNIZED];
    }
    showToast({
        variant: "error",
        text,
        desc: event_id ? `(code: ${event_id})` : undefined,
    });
};

export const showApiError = (err: unknown) => {
    const customError = err as CustomError;
    showRequestErrorToast(customError.error, customError.event_id, customError.fields);
};

export default showToast;
