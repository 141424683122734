import { useTranslation } from "react-i18next";
import ErrorScreenTemplate from "../../shared/components/ErrorScreenTemplate";

const NotFoundScreen: React.FC = () => {
    const { t } = useTranslation();

    return <ErrorScreenTemplate title={t("not_found_screen.title")} description={t("not_found_screen.description")} />;
};

export default NotFoundScreen;
