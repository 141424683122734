import { CsrvFileInfo } from "csrvfs";
import CsrvWebFTP from "csrvfs/dist/CsrvRemoteFS";
import { useCallback, useEffect } from "react";
import { parse } from "yaml";
import { useAppDispatch } from "../../../hooks";
import CustomError from "../../../shared/CustomError";
import { showApiError } from "../../../shared/helpers/showToast";
import { useLazyGetProjectQuery } from "../../Marketplace/api";
import { InstalledPackage } from "../../Marketplace/types";
import { setInstalledApps, setInstalledPackages } from "../slices/currentServerSlice";
import { ServerWithParameters } from "../types";

export default function (webFTP: CsrvWebFTP | null, server: ServerWithParameters | null) {
    const dispatch = useAppDispatch();
    const [getProject] = useLazyGetProjectQuery();

    const loading = false;

    const initPackages = useCallback(async () => {
        if (!webFTP || !server) {
            return;
        }

        dispatch(setInstalledApps({ loading: true }));
        dispatch(setInstalledPackages({ loading: true }));

        let installedPlugins: CsrvFileInfo[] = [];

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const craftserveStat = await webFTP.ls("/.craftserve");

        try {
            installedPlugins = await webFTP.ls("/.kloceg/installed/", {
                depth: "1",
            });
        } catch (e) {
            installedPlugins = [];
        }

        let installedApps: CsrvFileInfo[] = [];
        try {
            if (craftserveStat.find((elem) => elem.name === "apps")) {
                installedApps = await webFTP.ls("/.craftserve/apps/shared/", {
                    depth: "1",
                });
            } else {
                throw new Error("No apps folder");
            }
        } catch (e) {
            installedApps = [];
        }
        const pluginsData = await Promise.all(
            installedPlugins
                .filter((file) => {
                    const fileExtension = file.name.split(".").pop();
                    return fileExtension === "yml" && file.name.split("/").length === 1;
                })
                .map(async (file) => {
                    try {
                        const data = await webFTP.read(`/.kloceg/installed/${file.name}`);
                        const text = await data.text();
                        return parse(text);
                    } catch (e) {
                        showApiError(e);
                        return null;
                    }
                })
        ).catch(() => {
            return [];
        });

        const installedMap = pluginsData.reduce(
            (res, elem) => {
                res[elem.manifest.name] = {
                    version: elem.manifest.version,
                    hash: elem["manifest-hash"],
                    provides: elem.manifest.provides,
                    labels: elem.manifest.labels,
                };

                return res;
            },
            {} as Record<string, InstalledPackage>
        ) as Record<string, InstalledPackage>;

        dispatch(
            setInstalledPackages({
                data: installedMap,
            })
        );

        if (installedApps) {
            Promise.all(
                installedApps.map(async (appData) => {
                    const appName = appData.name;
                    const project = await getProject(appName).unwrap();
                    const projectPackage = pluginsData.find((elem) => elem.manifest.name === appName);
                    const packageTime = projectPackage["manifest-hash"];

                    return { ...project, packageTime };
                })
            )
                .then((appsWithTimes) => {
                    const appsWithValidTimes = appsWithTimes.filter((app) => app.packageTime !== null);
                    const sortedApps = appsWithValidTimes.sort(
                        (a, b) => new Date(b.packageTime).getTime() - new Date(a.packageTime).getTime()
                    );

                    dispatch(
                        setInstalledApps({
                            data: sortedApps,
                        })
                    );
                })
                .catch((error) => {
                    const err = error as CustomError;
                    dispatch(
                        setInstalledApps({
                            error: err,
                        })
                    );
                });
        } else {
            dispatch(
                setInstalledApps({
                    data: [],
                })
            );
        }
    }, [dispatch, getProject, server, webFTP]);

    useEffect(() => {
        initPackages();
    }, [webFTP, server, initPackages]);

    return { refresh: initPackages, loading };
}
