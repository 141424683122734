import { RootState } from "../../../rootReducer";

export const getServerStatus = (state: RootState) => state.serverStatus;
export const isServerStatusLoading = (state: RootState) => state.serverStatus.loading;
export const routeAlertSelector = (state: RootState) => state.routeAlert.showAlertOnRouteChange;
export const getPointerToasts = (state: RootState) => state.pointerToasts.toasts;
export const getCurrentServer = (state: RootState) => state.currentData.server;
export const getCurrentService = (state: RootState) => state.currentData.service;
export const getCurrentServiceCost = (state: RootState) => state.currentData.serviceCost;
export const getCurrentWallet = (state: RootState) => state.currentData.wallet;
export const getPrimaryEngine = (state: RootState) => state.currentData.primaryEngine;
export const getInstalledPackages = (state: RootState) => state.currentData.installedPackages;
export const getInstalledApps = (state: RootState) => state.currentData.installedApps;
export const getStatsLogs = (state: RootState) => state.consoleLogs.stats;
export const getTotalBytes = (state: RootState) => state.consoleLogs.totalBytes;
export const getHistoryBytes = (state: RootState) => state.consoleLogs.historyBytes;
export const getOpenedFiles = (state: RootState) => state.fileManagerData.openedFiles;
export const getModifiedPaths = (state: RootState) => state.fileManagerData.modifiedPaths;
export const getFilesProgress = (state: RootState) => state.fileManagerData.currentFilesProgress;
export const getTree = (state: RootState) => state.fileManagerData.tree;
