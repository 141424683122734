import styled from "styled-components";

export const VoucherTargetEditorContainer = styled.div<{ isLoading: boolean }>`
    display: flex;

    margin-top: 20px;
    width: 100%;
    gap: 10px;
    position: relative;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }

    ${({ isLoading }) =>
        isLoading &&
        `
        pointer-events: none;
        opacity: 0.5;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.5);
            z-index: 1;
        }
    `}
`;
