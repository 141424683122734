import { array, Infer, object } from "superstruct";
import { Money } from "ts-money";
import { Transaction } from "../../shared/types";
import { Domain, FormVoucherTarget, ServerWithParameters, VoucherAction } from "../Server/types";
import { User } from "../User/types";

export interface AdminSearchOptions {
    q: string;
}

export const AdminSearchResponse = object({
    users: array(User),
    servers: array(ServerWithParameters),
    transactions: array(Transaction),
    domains: array(Domain),
});

export type AdminSearchResponse = Infer<typeof AdminSearchResponse>;

export interface FormVoucherAction extends Omit<VoucherAction, "wallet_tx" | "targets"> {
    id: number;
    wallet_tx: Money[];
    targets?: FormVoucherTarget[];
}

export interface CreateNewVoucherFormValues {
    length: number;
    prefix: string;
    charset: string;
    expires: string;
    group_id: string;
    uses: number;
    per_user: number;
    actions: FormVoucherAction[];
}

export interface CreateNewVoucherPayload {
    length: number;
    charset: string;
    prefix?: string;
    expires?: string;
    group_id?: string;
    uses?: number;
    per_user?: number;
    actions: VoucherAction[];
}
