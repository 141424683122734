import { ExternalLink, Paragraph, Space, Title } from "csrvui";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import paperWrap2 from "../../assets/images/home-paperwrap2.png";
import { featuredPackagesSlugs, trustedUs } from "../../constants";
import { useGetProductsQuery } from "../../modules/Billings/api";
import { BillingProduct } from "../../modules/Billings/types";
import Marketplace from "../../modules/Marketplace/components/Marketplace";
import RedeemFreeServerModal from "../../modules/Marketplace/components/RedeemFreeServerModal";
import standarizeUrl from "../../modules/Marketplace/helpers/standarizeUrl";
import useMarketplace from "../../modules/Marketplace/hooks/useMarketplace";
import ServersOffers from "../../modules/Server/components/LandingProductsList";
import useUserVouchers from "../../modules/User/hooks/useUserVouchers";
import useVerify from "../../modules/User/hooks/useVerify";
import HomeIntroduction from "../../shared/components/HomeIntroduction";
import LandingCarousel from "../../shared/components/LandingCarousel";
import LandingPanelGrid from "../../shared/components/LandingPanelGrid";
import LandingWhyUsCard from "../../shared/components/LandingWhyUsGrid";
import Layout from "../../shared/components/Layout";
import MoreInfoModal from "../../shared/components/MoreInfoModal";
import showToast from "../../shared/helpers/showToast";
import useTheme from "../../shared/hooks/useCustomTheme";
import { themes } from "../../shared/hooks/useDarkMode";
import { VisualLinkMode } from "../../shared/types";
import { Grid, OffersSection, StyledDiv, StyledPaperWrap, StyledWhyUsBackground } from "./style";

const featuredMarketplaceProducts = [
    ...featuredPackagesSlugs,
    "1khjsgo2u7cow-all-the-mods-9---to-the-sky---atm9sky",
    "1khjweevy5b9o-dawncraft---echoes-of-legends",
    "1kha6b91a5zht-rlcraft",
];

const HomeScreen: React.FC = () => {
    const { t } = useTranslation();
    const { data: products, isLoading } = useGetProductsQuery();
    const theme = useTheme();
    const isMobile = window.innerWidth < 541;
    const [renderMarketplace, setRenderMarketplace] = useState(false);
    const marketplaceRef = useRef<HTMLDivElement>(null);
    const { userAlreadyTestedServer } = useUserVouchers();
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState<{
        isOpen: boolean;
        product: BillingProduct | null;
    }>({
        isOpen: false,
        product: null,
    });
    const [params] = useSearchParams();
    const openRedeemModal = params.get("redeem-free-server");
    const hidden = useMemo(() => ["BASIC_CRAFTUM", "EXTRA_STORAGE"], []);
    const location = useLocation();
    const { isLoading: isVerificationLoading, isLoggedIn } = useVerify();
    const filteredProducts = useMemo(() => {
        if (!products) return [];

        return products.filter((p) => p.depends_on_products.length === 0 && !hidden.includes(p.id));
    }, [hidden, products]);

    const [modalSettings, setModalSettings] = useState<{
        isOpen: boolean;
        product: BillingProduct | null;
    }>({
        isOpen: false,
        product: null,
    });

    useEffect(() => {
        if (isVerificationLoading) {
            return;
        }
        const params = new URLSearchParams(location.search);
        const redeemFreeServer = params.get("redeemfreeserver");

        if (redeemFreeServer && !isLoggedIn) {
            const targetProduct = filteredProducts.find((product) => product.id === redeemFreeServer);
            if (targetProduct && targetProduct.available) {
                setModalSettings({
                    isOpen: true,
                    product: targetProduct,
                });
            }
        }
    }, [location, filteredProducts, isLoggedIn, isVerificationLoading]);

    useEffect(() => {
        if (!openRedeemModal || isLoading) return;

        if (filteredProducts.length === 0) {
            showToast({
                variant: "error",
                text: t("landing.errorRedeemFreeServer"),
            });
            return;
        }

        const productToRedeem = [...filteredProducts].reverse().find((product) => !userAlreadyTestedServer(product.id));

        if (productToRedeem) {
            setModalSettings({ isOpen: true, product: productToRedeem });
        } else {
            showToast({
                variant: "error",
                text: t("landing.you_already_redeemed_free_server"),
            });
        }
    }, [products, isLoading, openRedeemModal, filteredProducts, t, userAlreadyTestedServer]);

    const { projectsLoading, packages, packagesLoading } = useMarketplace({
        limit: 100,
        name: "",
    });

    useEffect(() => {
        if (renderMarketplace) return undefined;
        const target = marketplaceRef.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setRenderMarketplace(true);
                    observer.disconnect();
                }
            },
            { threshold: 0 }
        );

        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [renderMarketplace]);

    return (
        <Layout>
            <HomeIntroduction />
            {/* <StyledPaperWrap background={paperWrap} marginTop="-180px" height="300px" /> */}
            <OffersSection>
                <ServersOffers
                    products={filteredProducts}
                    onSelectProduct={(isOpen, product) => {
                        setMoreInfoModalOpen({ isOpen, product });
                    }}
                />
                <Title
                    $color={theme.colors.secondaryText}
                    style={{ textTransform: "uppercase", marginTop: "45px" }}
                    $centered
                    $level={2}
                >
                    {t("landing.marketplace_title")}
                </Title>
                <StyledDiv ref={marketplaceRef}>
                    {renderMarketplace && (
                        <Marketplace
                            disableEventInfo
                            packagesLoading={packagesLoading}
                            projectsLoading={projectsLoading}
                            labels=""
                            useLightVariant
                            isPublic
                            options={{
                                defaultSections: {
                                    disableFeatured: true,
                                    disablePackages: true,
                                    disableRowPackages: true,
                                },
                                disableMinHeight: true,
                                additionalSections: [
                                    {
                                        name: "landing",
                                        items:
                                            packages &&
                                            packages.filter((p) => featuredMarketplaceProducts.includes(p.slug)),
                                    },
                                ],
                            }}
                        />
                    )}
                </StyledDiv>
                <Space
                    $direction="vertical"
                    $align="center"
                    style={{ padding: "30px 0 0 0", maxWidth: "100%", zIndex: 2, position: "relative" }}
                >
                    <Paragraph
                        $level={2}
                        $color={themes.light.colors.white}
                        style={{ fontWeight: 500, paddingBottom: 10, textAlign: "center" }}
                    >
                        {t("landing.not_all_we_offer")}
                    </Paragraph>

                    <ExternalLink
                        data-ph-capture-attribute-id="home_screen:check_our_marketplace"
                        $mode={VisualLinkMode.SHOW}
                        $variant="primary"
                        $sizeVariant="big"
                        $filled
                        $underline={false}
                        href="m"
                        style={{
                            padding: "20px 10px",
                            width: isMobile ? "auto" : 400,
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            textTransform: "uppercase",
                            backgroundColor: "#28A745",
                            boxShadow: "0px 0px 15px 9px #28A74540",
                            fontWeight: 700,
                            marginTop: 4,
                            fontSize: 24,
                        }}
                    >
                        {t("landing.check_our_marketplace")}
                    </ExternalLink>
                </Space>
            </OffersSection>

            <StyledPaperWrap
                background={standarizeUrl(new URL(paperWrap2, import.meta.url).toString())}
                marginTop="-100px"
                height="180px"
            />

            <Space $gap={20} $padding="80px 0 60px 0">
                <Title
                    $centered
                    style={{
                        textTransform: "uppercase",
                        color: theme.colors.primary,
                        fontWeight: "bold",
                    }}
                    $level={2}
                >
                    {t("landing.trusted_us")}
                </Title>
                <LandingCarousel items={trustedUs} />
            </Space>
            <StyledWhyUsBackground>
                <Title
                    style={{ margin: "30px 0 30px 0", textTransform: "uppercase" }}
                    $color={theme.colors.secondaryText}
                    $centered
                    $level={2}
                >
                    {t("landing.whyUs")}
                </Title>
                <Grid>
                    <LandingWhyUsCard />
                </Grid>
            </StyledWhyUsBackground>
            <Space
                $gap={30}
                $padding={0}
                style={{
                    background: themes.light.colors.primaryBackground,
                    paddingBottom: 100,
                }}
            >
                <Space $direction="vertical" $align="center" style={{ marginTop: 50 }}>
                    <Title
                        style={{ margin: "0 0 20px 0", fontWeight: 750, textTransform: "uppercase" }}
                        $color={theme.colors.primary}
                        $centered
                        $level={2}
                    >
                        {t("landing.discoverOurPanel")}
                    </Title>
                    <LandingPanelGrid />
                </Space>
            </Space>

            {moreInfoModalOpen.product ? (
                <MoreInfoModal
                    isOpen={moreInfoModalOpen.isOpen}
                    onClose={() => setMoreInfoModalOpen({ ...moreInfoModalOpen, isOpen: false })}
                    product={moreInfoModalOpen.product}
                />
            ) : (
                <p> </p>
            )}

            <RedeemFreeServerModal
                open={modalSettings.isOpen}
                product={modalSettings.product}
                onClose={() => setModalSettings({ isOpen: false, product: null })}
            />
        </Layout>
    );
};

export default HomeScreen;
