const formatFormikError = (error: unknown): string => {
    if (typeof error === "string") {
        return error;
    }
    if (Array.isArray(error)) {
        return error.map((err) => formatFormikError(err)).join(", ");
    }
    if (typeof error === "object" && error !== null) {
        return Object.entries(error)
            .map(([field, err]) => `${field}: ${formatFormikError(err)}`)
            .join("; ");
    }
    return "";
};

export default formatFormikError;
