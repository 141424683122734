/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { equals } from "ramda";
import { Money } from "ts-money";
import CustomError from "../../../shared/CustomError";
import * as BillingsTypes from "../../Billings/types";
import { InstalledPackage, MarketProject } from "../../Marketplace/types";
import * as UserTypes from "../../User/types";
import * as ServerTypes from "../types";

export interface LoadingState {
    loading: true;
    error: null;
    data: undefined;
}

interface ErrorState {
    loading: false;
    error: CustomError;
    data: null;
}

interface SuccessState<T> {
    loading: false;
    error: null;
    data: T;
}

export type ServerState = LoadingState | ErrorState | SuccessState<ServerTypes.ServerWithParameters>;
export type ServiceState = LoadingState | ErrorState | SuccessState<BillingsTypes.BillingService>;
export type ServiceCostState = LoadingState | ErrorState | SuccessState<Money>;
export type WalletState = LoadingState | ErrorState | SuccessState<UserTypes.Wallet>;
export type InstalledPackagesState = LoadingState | ErrorState | SuccessState<Record<string, InstalledPackage>>;
export type InstalledAppsState = LoadingState | ErrorState | SuccessState<MarketProject[]>;

interface SuccessPayload<T> {
    data: T;
}

interface LoadingPayload {
    loading: boolean;
}

interface ErrorPayload {
    error: CustomError;
}

interface CurrentServerSliceState {
    server: ServerState;
    service: ServiceState;
    primaryEngine?: string | null;
    serviceCost: ServiceCostState;
    wallet: WalletState;
    installedPackages: InstalledPackagesState;
    installedApps: InstalledAppsState;
}

const defaultInitialState: LoadingState = {
    loading: true,
    error: null,
    data: undefined,
};

const currentDataSlice = createSlice({
    name: "currentData",
    initialState: {
        server: defaultInitialState,
        service: defaultInitialState,
        primaryEngine: null,
        serviceCost: defaultInitialState,
        wallet: defaultInitialState,
        installedPackages: defaultInitialState,
        installedApps: defaultInitialState,
    } as CurrentServerSliceState,
    reducers: {
        setCurrentServer: (
            state,
            { payload }: PayloadAction<SuccessPayload<ServerTypes.ServerWithParameters> | ErrorPayload>
        ) => {
            if ("error" in payload) {
                state.server = {
                    data: null,
                    loading: false,
                    error: payload.error,
                };
            } else if (!equals(state.server.data, payload.data)) {
                state.server = {
                    data: payload.data,
                    loading: false,
                    error: null,
                };

                if (payload.data !== null && state.primaryEngine !== payload.data.parameters.game?.engine) {
                    state.primaryEngine = payload.data.parameters.game?.engine || null;
                }
            }
        },
        setCurrentService: (
            state,
            { payload }: PayloadAction<SuccessPayload<BillingsTypes.BillingService> | ErrorPayload>
        ) => {
            if ("error" in payload) {
                state.service = {
                    data: null,
                    loading: false,
                    error: payload.error,
                };
            } else if (!equals(state.service.data, payload.data)) {
                state.service = {
                    data: payload.data,
                    loading: false,
                    error: null,
                };
            }
        },
        setCurrentServiceCost: (
            state,
            { payload }: PayloadAction<SuccessPayload<Money> | LoadingPayload | ErrorPayload>
        ) => {
            if ("loading" in payload) {
                state.serviceCost.loading = payload.loading;
                return;
            }
            if ("error" in payload) {
                state.serviceCost = {
                    data: null,
                    loading: false,
                    error: payload.error,
                };
                return;
            }

            state.serviceCost.loading = false;

            if (!equals(state.serviceCost.data, payload.data)) {
                state.serviceCost = {
                    data: payload.data,
                    loading: false,
                    error: null,
                };
            }
        },
        setCurrentWallet: (
            state,
            { payload }: PayloadAction<SuccessPayload<UserTypes.Wallet> | LoadingPayload | ErrorPayload>
        ) => {
            if ("loading" in payload) {
                state.wallet.loading = payload.loading;
                return;
            }

            if ("error" in payload) {
                state.wallet = {
                    data: null,
                    loading: false,
                    error: payload.error,
                };
                return;
            }

            state.wallet.loading = false;

            if (!equals(state.wallet.data, payload.data)) {
                state.wallet = {
                    data: payload.data,
                    loading: false,
                    error: null,
                };
            }
        },
        setInstalledPackages: (
            state,
            { payload }: PayloadAction<SuccessPayload<Record<string, InstalledPackage>> | LoadingPayload | ErrorPayload>
        ) => {
            if ("loading" in payload) {
                state.installedPackages.loading = payload.loading;
                return;
            }

            if ("error" in payload) {
                state.installedPackages = {
                    data: null,
                    loading: false,
                    error: payload.error,
                };
                return;
            }

            state.installedPackages.loading = false;

            if (!equals(state.installedPackages.data, payload.data)) {
                state.installedPackages = {
                    data: payload.data,
                    loading: false,
                    error: null,
                };
            }
        },
        setInstalledApps: (
            state,
            { payload }: PayloadAction<SuccessPayload<MarketProject[]> | LoadingPayload | ErrorPayload>
        ) => {
            if ("loading" in payload) {
                state.installedApps.loading = payload.loading;
                return;
            }

            if ("error" in payload) {
                state.installedApps = {
                    data: null,
                    loading: false,
                    error: payload.error,
                };
                return;
            }

            state.installedApps.loading = false;

            if (!equals(state.installedApps.data, payload.data)) {
                state.installedApps = {
                    data: payload.data,
                    loading: false,
                    error: null,
                };
            }
        },

        clearSlice: (state) => {
            state.server = defaultInitialState;
            state.service = defaultInitialState;
            state.serviceCost = defaultInitialState;
            state.primaryEngine = null;
            state.wallet = defaultInitialState;
            state.installedPackages = defaultInitialState;
            state.installedApps = defaultInitialState;
        },
    },
});

export const {
    setCurrentServer,
    setCurrentService,
    setCurrentServiceCost,
    setCurrentWallet,
    setInstalledPackages,
    setInstalledApps,
    clearSlice,
} = currentDataSlice.actions;

export default currentDataSlice.reducer;
