import { Paragraph, Space } from "csrvui";
import CustomScreenTemplate from "../CustomScreenTemplate";
import { ErrorDescription, ErrorMessage } from "./style";
import { Props } from "./types";

const ErrorScreenTemplate: React.FC<Props> = ({ title, description, eventId }) => (
    <CustomScreenTemplate>
        <ErrorMessage>{title}</ErrorMessage>
        <ErrorDescription $level={3}>{description}</ErrorDescription>
        <Space>{eventId && <Paragraph>{eventId}</Paragraph>}</Space>
    </CustomScreenTemplate>
);

export default ErrorScreenTemplate;
