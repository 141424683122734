import { Paragraph, Space, Title } from "csrvui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Category, featuredPackagesSlugs, FeatureFlags } from "../../../../constants";
import { MaxWidthContainer } from "../../../../shared/components/MaxWidthContainer/style";
import { getFeatureFlag } from "../../../../shared/helpers/sessionStorage";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import useMetrics from "../../hooks/useMetrics";
import usePackageCreationListener from "../../hooks/usePackageCreationListener";
import { MarketProject } from "../../types";
import EventAlert from "../EventAlert";
import EventRanking from "../EventRanking";
import MarketplaceProjectRow from "../MarketplaceProjectRow";
import PackageCreationProgressModal from "../PackageCreationProgressModal";
import VirtualizedMarketplace from "../VirtualizedMarketplaceItems";
import MarketplaceCard from "./MarketplaceCard";
import { Grid, ShowcaseGrid } from "./style";

const PROJECTS_ESTIMATED_COUNT = 12;
interface Props {
    projects?: MarketProject[];
    packagesLoading?: boolean;
    packages?: MarketProject[];
    engines?: MarketProject[];
    projectsLoading: boolean;
    labels: string;
    blacklist?: string[];
    followedProjects?: MarketProject[];
    isPublic?: boolean;
    isLanding?: boolean;
    disableAdditionals?: boolean;
    useLightVariant?: boolean;
    disableEventInfo?: boolean;
    options?: {
        disableMinHeight?: boolean;
        defaultSections?: {
            disablePackages?: boolean;
            disableFeatured?: boolean;
            disableRowPackages?: boolean;
        };
        additionalSections: {
            name: string;
            estimatedCount?: number;
            items?: MarketProject[];
        }[];
    };
}

const Marketplace: React.FC<Props> = ({
    projects,
    projectsLoading,
    // blacklist = [],
    followedProjects,
    isPublic = false,
    isLanding = false,
    packages,
    packagesLoading,
    useLightVariant = false,
    disableEventInfo = false,
    options,
}) => {
    const { t } = useTranslation();
    const { serverIdentity } = useParams();
    const theme = useTheme();
    const marketplaceUrl = isPublic ? "/m" : `/s/${serverIdentity}/m`;
    const { handleClose, isFailed, isModalOpen } = usePackageCreationListener();
    const { data } = useMetrics({ label: "csrv_konkurs_11_2024", metricType: "install" });
    const isEvent = getFeatureFlag(FeatureFlags.CSRV_EVENT);

    const isMobile = window.innerWidth < Number(theme.mobileBreakpoint.replace(/\D/g, ""));
    const scrollElement = useMemo(
        () => (isPublic || isMobile ? window : document.getElementById("panel-content-wrapper")),
        [isPublic, isMobile]
    );

    const featuredPackages = useMemo(() => {
        if (packages) {
            return packages.filter((project) => featuredPackagesSlugs.includes(project.slug));
        }

        return Array.from({ length: featuredPackagesSlugs.length }).map((_, index) => ({
            mock: `mock-${index}`,
        }));
    }, [packages]);

    // const serverPackages = projects
    // ? projects.filter((project) => !project.labels || !project.labels.includes(Category.SERVERPACK))
    // : [];

    const serverPackages = useMemo(() => {
        if (projects) {
            return projects.filter((project) => !project.labels || !project.labels.includes(Category.SERVERPACK));
        }

        return Array.from({ length: PROJECTS_ESTIMATED_COUNT }).map((_, index) => ({
            mock: `mock-${index}`,
        }));
    }, [projects]);

    const totalResults =
        (projects ? projects.length : 0) +
        (packages ? packages.length : 0) +
        (options?.additionalSections
            ? options.additionalSections.reduce((acc, section) => acc + (section.items ? section.items.length : 0), 0)
            : 0) +
        (followedProjects ? followedProjects.length : 0);

    const filteredFollowedPackages = followedProjects
        ? followedProjects.filter((project) => project.labels && project.labels.includes(Category.SERVERPACK))
        : [];

    const isDefaultPackagesSectionDisabled =
        options && options.defaultSections && options.defaultSections.disablePackages;

    const packagesNotFound = !packagesLoading && packages?.length === 0;

    const serverPackagesNotFound = !projectsLoading && serverPackages.length === 0;

    const isDefaultFeaturedSectionDisabled =
        options && options.defaultSections && options.defaultSections.disableFeatured;

    const isDefaultRowPackagesSectionDisabled =
        options && options.defaultSections && options.defaultSections.disableRowPackages;

    const featuredPackagesNotFound = !packagesLoading && featuredPackages.length === 0;

    return (
        <>
            <MaxWidthContainer
                style={{
                    minHeight: options && options.disableMinHeight ? "auto" : "100vh",
                    height: "fit-content",
                }}
            >
                {!disableEventInfo && isEvent && (
                    <Space
                        $padding={0}
                        style={{
                            margin: "10px 0",
                        }}
                    >
                        {data && <EventRanking data={data} />}
                        <EventAlert />
                    </Space>
                )}
                {!isDefaultFeaturedSectionDisabled && !featuredPackagesNotFound && (
                    <>
                        <Space $align="space-between" $oppositeAlign="center" $direction="horizontal" $padding={0}>
                            <Paragraph
                                $color={useLightVariant ? theme.colors.secondaryText : theme.colors.primaryText}
                                $level={3}
                                style={{
                                    fontWeight: 550,
                                    display: !packagesLoading && featuredPackages.length === 0 ? "none" : "block",
                                }}
                            >
                                {t("marketplace.featured")}
                            </Paragraph>
                        </Space>
                        <ShowcaseGrid>
                            {featuredPackages.map((project) => {
                                const data = "mock" in project ? undefined : project;
                                const slug = "mock" in project ? "" : project.slug;
                                const key = "mock" in project ? project.mock : project.id;

                                return <MarketplaceCard to={`${marketplaceUrl}/${slug}`} key={key} data={data} />;
                            })}
                        </ShowcaseGrid>
                    </>
                )}

                {totalResults === 0 && !packagesLoading && !projectsLoading && (
                    <Space $fullWidth $align="center">
                        <Space>
                            <Title $color={isLanding ? "white" : undefined}>{t("marketplace.not_found_title")}</Title>
                            <Paragraph $color={isLanding ? "white" : undefined}>
                                {t("marketplace.not_found_text")}
                            </Paragraph>
                        </Space>
                    </Space>
                )}

                {options &&
                    options.additionalSections.length > 0 &&
                    options.additionalSections.map(({ items, name, estimatedCount = 6 }) => {
                        const projects =
                            items ||
                            Array.from({ length: estimatedCount }).map((_, index) => ({
                                mock: `mock-${index}`,
                            }));

                        return (
                            <ShowcaseGrid key={name}>
                                {projects.map((project) => (
                                    <MarketplaceCard
                                        to={`${marketplaceUrl}/${"slug" in project ? project.slug : ""}`}
                                        key={"id" in project ? project.id : project.mock}
                                        data={"mock" in project ? undefined : project}
                                    />
                                ))}
                            </ShowcaseGrid>
                        );
                    })}

                {!isDefaultPackagesSectionDisabled && !packagesNotFound && (
                    <>
                        <Space
                            $align="space-between"
                            $oppositeAlign="center"
                            $direction="horizontal"
                            $padding="0 0 15px 0"
                        >
                            <Paragraph
                                $color={useLightVariant ? theme.colors.secondaryText : theme.colors.primaryText}
                                $level={3}
                                style={{
                                    fontWeight: 550,
                                    display: !packagesLoading && packages?.length === 0 ? "none" : "block",
                                }}
                            >
                                {t("landing.explore")}
                            </Paragraph>
                        </Space>
                        <div
                            style={{
                                padding: "0",
                            }}
                        >
                            <VirtualizedMarketplace
                                scrollElement={scrollElement as HTMLElement}
                                isLoading={packagesLoading}
                                data={packages}
                                marketplaceUrl={marketplaceUrl}
                            />
                        </div>
                    </>
                )}
                {followedProjects && followedProjects.length > 0 && (
                    <Space $gap={15} $padding={0}>
                        <Space
                            $align="space-between"
                            $oppositeAlign="center"
                            $direction="horizontal"
                            $padding="15px 0 0 0"
                        >
                            <Paragraph
                                $color={useLightVariant ? theme.colors.secondaryText : theme.colors.primaryText}
                                $level={3}
                                style={{ fontWeight: 550 }}
                            >
                                {t("landing.observed")}
                            </Paragraph>
                        </Space>
                        {filteredFollowedPackages.length > 0 && (
                            <Grid style={{ padding: "0" }}>
                                {filteredFollowedPackages
                                    .filter((project) => project.labels.includes(Category.SERVERPACK))
                                    .map((project) => (
                                        <MarketplaceCard
                                            to={`${marketplaceUrl}/${project.slug}`}
                                            key={project.id}
                                            data={project}
                                        />
                                    ))}
                            </Grid>
                        )}
                        <Space $padding="0">
                            {followedProjects
                                .filter((project) => !project.labels.includes(Category.SERVERPACK))
                                .map((project) => (
                                    <MarketplaceProjectRow
                                        to={`${marketplaceUrl}/${project.slug}`}
                                        key={project.id}
                                        project={project}
                                    />
                                ))}
                        </Space>
                    </Space>
                )}

                {!isDefaultRowPackagesSectionDisabled && !serverPackagesNotFound && (
                    <>
                        <Space $align="space-between" $oppositeAlign="center" $direction="horizontal" $padding={0}>
                            <Paragraph
                                $color={useLightVariant ? theme.colors.secondaryText : theme.colors.primaryText}
                                $level={3}
                                style={{ fontWeight: 550, padding: "20px 0" }}
                            >
                                {t("landing.plugins_text")}
                            </Paragraph>
                        </Space>
                        <Space $padding={0}>
                            {serverPackages.map((project) => (
                                <MarketplaceProjectRow
                                    to={`${marketplaceUrl}/${"slug" in project ? project.slug : ""}`}
                                    key={"id" in project ? project.id : project.mock}
                                    project={"mock" in project ? undefined : project}
                                />
                            ))}
                        </Space>
                    </>
                )}
            </MaxWidthContainer>
            <PackageCreationProgressModal error={isFailed} isOpen={isModalOpen} onClose={handleClose} />
        </>
    );
};

export default Marketplace;
