import { Paragraph, Title } from "csrvui";
import styled from "styled-components";

export const ErrorMessage = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 60px;
`;

export const ErrorDescription = styled(Paragraph)`
    max-width: 100ch;
`;
