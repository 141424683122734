import { useContext, useMemo } from "react";
import { useAppSelector } from "../../hooks";
import usePreviewState from "../../modules/Server/hooks/usePreviewState";
import { selectCurrentUser } from "../../modules/User/slices/selectors";
import { CurrentServerContext } from "../components/CurrentServerProvider";
import { hasCapabilityInObject } from "../helpers/capabilities";
import { Capability } from "../types";

export default function usePermissions() {
    const user = useAppSelector(selectCurrentUser);
    const capabilities = useMemo(() => (user ? user.user_capabilities : {}), [user]);
    const { server } = useContext(CurrentServerContext);
    const [isPreview] = usePreviewState();

    const isAdmin = useMemo(() => {
        return hasCapabilityInObject(capabilities, Capability.IsAdmin) && !isPreview;
    }, [capabilities, isPreview]);

    return {
        canManageCurrentServer:
            (server &&
                server.user_capabilities &&
                hasCapabilityInObject(server.user_capabilities, Capability.CanManageCurrentServer)) ||
            hasCapabilityInObject(capabilities, Capability.CanManageServer),
        canCreateMarketplaceProject: hasCapabilityInObject(capabilities, Capability.CanCreateMarketplaceProject),
        isAdmin,
        canAdminMenu: hasCapabilityInObject(capabilities, Capability.IsAdmin),
        canManageServersAsAdmin: hasCapabilityInObject(capabilities, Capability.CanManageServersAsAdmin),
        canManageServerParameters: hasCapabilityInObject(capabilities, Capability.CanManageServerParameters),
        canCreateVoucher: hasCapabilityInObject(capabilities, Capability.CanCreateVoucher),
        canTopupAsAdmin: hasCapabilityInObject(capabilities, Capability.CanTopupAsAdmin),
        canUserBrowseMarketProjects: hasCapabilityInObject(capabilities, Capability.CanBrowseMarketplaceProjects),
        canEditMarketProject: hasCapabilityInObject(capabilities, Capability.CanEditMarketplaceProject),
        canReadMarketProject: hasCapabilityInObject(capabilities, Capability.CanReadMarketplaceProject),
        canCreateProjectWithoutSuffix: hasCapabilityInObject(capabilities, Capability.CanCreateProjectWithoutSuffix),
    };
}
