import { Paragraph, Space } from "csrvui";
import React from "react";
import useTheme from "../../hooks/useCustomTheme";
import { LandingCarouselItem } from "../../types";
import { ImageWrapper, LandingCarouselItemWrapper } from "./styles";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
    data: LandingCarouselItem;
}

const LandingCarouselCard = ({ data, ...rest }: Props) => {
    const theme = useTheme();
    const { imageSrc, name, quote } = data;

    return (
        <LandingCarouselItemWrapper {...rest}>
            <Space $fullWidth style={{ maxWidth: "350px" }} $padding={0}>
                <ImageWrapper isMobile>
                    <img width={100} height={100} src={imageSrc} alt={name} />
                </ImageWrapper>
                <Space $align="center" $oppositeAlign="center" $direction="horizontal" $padding={0}>
                    <Paragraph $level={2} style={{ color: theme.colors.secondaryText, fontWeight: 600 }}>
                        {name}
                    </Paragraph>
                </Space>
                <Paragraph $level={3} style={{ color: theme.colors.secondaryText }}>
                    &quot;{quote}&quot;
                </Paragraph>
            </Space>
            <ImageWrapper isMobile={false}>
                <img src={imageSrc} alt={name} height={150} width={150} />
            </ImageWrapper>
        </LandingCarouselItemWrapper>
    );
};

export default LandingCarouselCard;
