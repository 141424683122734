import { ComponentType, LazyExoticComponent, lazy } from "react";

type ComponentImportType<T> = () => Promise<{ default: ComponentType<T> }>;

const lazyWithRetry = <T>(
    componentImport: ComponentImportType<T>,
    name: string
): LazyExoticComponent<ComponentType<T>> => {
    return lazy(
        () =>
            new Promise<{ default: ComponentType<T> }>((resolve, reject) => {
                const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false");

                componentImport()
                    .then((component) => {
                        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false");
                        resolve(component);
                    })
                    .catch((error) => {
                        if (!hasRefreshed) {
                            window.sessionStorage.setItem(`retry-${name}-refreshed`, "true");
                            window.location.reload();
                        } else {
                            reject(error);
                        }
                    });
            })
    );
};

export default lazyWithRetry;
