import styled from "styled-components";

export const LandingCarouselItemWrapper = styled.div`
    display: flex;
    max-width: 560px;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;

    // 100% - margin
    width: calc(100% - 20px);
    background: linear-gradient(269.7deg, #316c2d 10.81%, #1c5718 74.35%);
    border-radius: 18px;
    user-select: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    @media (max-width: 535px) {
        max-width: 100vw;
        width: 100%;
        border-radius: 0;
        align-items: center;
        min-height: 300px;
        text-align: center;
    }
`;

export const ImageWrapper = styled.div<{ isMobile: boolean }>`
    & img {
        border-radius: 18px;
    }

    ${(props) =>
        props.isMobile
            ? `
                @media (min-width: 536px) {
                    display: none;
                }
            `
            : `
                @media (max-width: 535px) {
                    display: none;
                }
            `}
`;
