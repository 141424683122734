import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import CsrvFile from "csrvfs/dist/Files/CsrvFile";
import Tree from "csrvfs/dist/Files/Tree";

interface FileManagerState {
    openedFiles: CsrvFile[];
    modifiedPaths: string[];
    currentFilesProgress: Record<string, string>;
    tree: Tree | null;
}

const slice = createSlice({
    name: "fileManagerSlice",
    initialState: {
        openedFiles: [],
        modifiedPaths: [],
        currentFilesProgress: {},
        tree: null,
    } as FileManagerState,
    reducers: {
        // Opened files
        setOpenedFiles(state, action: PayloadAction<CsrvFile[]>) {
            return {
                ...state,
                openedFiles: action.payload,
            };
        },
        addOpenedFile(state, action: PayloadAction<CsrvFile>) {
            return {
                ...state,
                openedFiles: [...state.openedFiles, action.payload],
            };
        },
        removeOpenedFile(state, action: PayloadAction<CsrvFile>) {
            return {
                ...state,
                openedFiles: state.openedFiles.filter((file) => file !== action.payload),
            };
        },
        closeAllFiles(state) {
            return {
                ...state,
                openedFiles: [],
            };
        },

        // Modified paths
        addModifiedPath(state, action: PayloadAction<string>) {
            return {
                ...state,
                modifiedPaths: [...state.modifiedPaths, action.payload],
            };
        },

        removeModifiedPath(state, action: PayloadAction<string>) {
            return {
                ...state,
                modifiedPaths: state.modifiedPaths.filter((path) => path !== action.payload),
            };
        },

        // this is used to store file data between routes to avoid fetching drafts in the same session
        updateFileProgress(state, action: PayloadAction<{ path: string; val: string }>) {
            return {
                ...state,
                currentFilesProgress: {
                    ...state.currentFilesProgress,
                    [action.payload.path]: action.payload.val,
                },
            };
        },

        removeFileProgress(state, action: PayloadAction<string>) {
            if (state.currentFilesProgress) {
                const { [action.payload]: _, ...rest } = state.currentFilesProgress;
                return {
                    ...state,
                    currentFilesProgress: rest,
                };
            }
            return state;
        },

        // tree
        setTree(state, action: PayloadAction<Tree>) {
            return {
                ...state,
                tree: action.payload,
            };
        },

        // clear file manager data
        clearFileManagerData() {
            return {
                openedFiles: [],
                modifiedPaths: [],
                currentFilesProgress: {},
                tree: null,
            };
        },
    },
});

export const {
    addOpenedFile,
    closeAllFiles,
    removeOpenedFile,
    setOpenedFiles,
    addModifiedPath,
    removeModifiedPath,
    updateFileProgress,
    removeFileProgress,
    setTree,
    clearFileManagerData,
} = slice.actions;

export default slice.reducer;
