import { TextInput } from "csrvui";
import { TextInputProps } from "csrvui/dist/esm/components/TextInput";
import { useTranslation } from "react-i18next";

interface MoneyInputProps extends Omit<TextInputProps, "onChange" | "type"> {
    onChange: (value: string) => void;
}

const MoneyInput = ({ onChange, ...props }: MoneyInputProps) => {
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        const formattedValue = value
            .replace(/[^0-9.,]/g, "")
            .replace(",", ".")
            .replace(/(.\d\d)\d+$/, "$1")
            .replace(/^0+(?=\d)(?!\.)/, "");

        onChange(formattedValue);
    };

    return (
        <TextInput
            $sizeVariant="big"
            placeholder={t("common.balance")}
            inputMode="numeric"
            min={0}
            pattern="[1-9][0-9]*"
            step={0.01}
            {...props}
            type="number"
            onChange={handleChange}
        />
    );
};

export default MoneyInput;
