import { Editor } from "@monaco-editor/react";
import { Paragraph, Space } from "csrvui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Money } from "ts-money";
import { Toggle } from "../../../../shared/components/CookiesBanner/style";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { CreateNewVoucherFormValues, CreateNewVoucherPayload } from "../../types";

const CreateVoucherSummary = ({ actions, ...rest }: CreateNewVoucherFormValues) => {
    const { t } = useTranslation();
    const [showJson, setShowJson] = useState(false);
    const theme = useTheme();

    const transformedValues: CreateNewVoucherPayload = {
        ...rest,
        actions: actions.map(({ id, wallet_tx, ...action }) => ({
            ...action,
            wallet_tx: wallet_tx ? Object.fromEntries(wallet_tx.map((tx) => [tx.currency, tx])) : undefined,
        })),
    };

    return (
        <Space $padding={0}>
            {actions.length > 0 && (
                <Paragraph style={{ fontWeight: 500 }}>{t("create_new_voucher.summary.on_use")}:</Paragraph>
            )}
            <Space
                style={{
                    backgroundColor: theme.colors.light,
                    borderRadius: "8px",
                    padding: "20px",
                    display: actions.length > 0 ? "flex" : "none",
                    gap: "20px",
                }}
                $padding="0 0 0 20px"
            >
                {actions.map(({ id, wallet_tx, add_hours, coupon, targets }, index) => (
                    <Space key={id} $padding={0}>
                        <Paragraph style={{ fontWeight: 500 }}>
                            {t("create_new_voucher.summary.action")} #{index + 1}
                        </Paragraph>
                        {add_hours && (
                            <Paragraph>{`${t("create_new_voucher.summary.add_hours")} ${add_hours}h`}</Paragraph>
                        )}
                        {wallet_tx &&
                            wallet_tx.length > 0 &&
                            wallet_tx
                                .filter((tx) => tx.amount && tx.currency)
                                .map((tx) => (
                                    <Paragraph
                                        key={`${tx.amount}-${tx.currency}`}
                                    >{`${t("create_new_voucher.summary.wallet_tx")} ${new Money(Number(tx.amount), tx.currency).toDecimal()} ${tx.currency}`}</Paragraph>
                                ))}
                        {coupon && (
                            <Space $padding={0}>
                                <Paragraph>{t("create_new_voucher.summary.coupon_label")}</Paragraph>
                                <Space $padding="0 20px 20px 20px">
                                    {coupon.name && (
                                        <Paragraph>
                                            {t("create_new_voucher.summary.coupon_name", {
                                                coupon: coupon.name,
                                            })}
                                        </Paragraph>
                                    )}
                                    {coupon.add_hours_on_renewal && (
                                        <Paragraph>
                                            {t("create_new_voucher.summary.add_hours_on_renewal", {
                                                coupon: coupon.add_hours_on_renewal,
                                            })}
                                        </Paragraph>
                                    )}
                                    {coupon.end_time && (
                                        <Paragraph>
                                            {t("create_new_voucher.summary.end_time", {
                                                coupon: coupon.end_time,
                                            })}
                                        </Paragraph>
                                    )}
                                    {coupon.expression && (
                                        <Paragraph>
                                            {t("create_new_voucher.summary.expression", {
                                                coupon: coupon.expression,
                                            })}
                                        </Paragraph>
                                    )}

                                    <Paragraph>
                                        {t("create_new_voucher.summary.uses_left", {
                                            coupon: coupon.uses_left,
                                        })}
                                    </Paragraph>
                                </Space>
                            </Space>
                        )}

                        {targets &&
                            targets.map((target) => (
                                <Space $gap={0} key={target.id} $padding={0}>
                                    <Paragraph style={{ color: theme.colors.danger, fontWeight: 500 }}>
                                        {t("create_new_voucher.summary.target")}
                                    </Paragraph>
                                    <Space $direction="horizontal" $padding={0}>
                                        {target.billing_period && (
                                            <Paragraph style={{ color: theme.colors.danger }}>
                                                {target.billing_period}
                                            </Paragraph>
                                        )}
                                        {target.cluster_id && (
                                            <Paragraph style={{ color: theme.colors.danger }}>
                                                {target.cluster_id}
                                            </Paragraph>
                                        )}
                                        <Paragraph style={{ color: theme.colors.danger }}>
                                            {target.product_id === "*"
                                                ? t("create_new_voucher.summary.all")
                                                : target.product_id}
                                        </Paragraph>
                                    </Space>
                                </Space>
                            ))}
                    </Space>
                ))}
            </Space>
            <Space $padding="20px 0 0 0">
                <Space $direction="horizontal" $align="space-between" $oppositeAlign="center" $padding={0}>
                    <Paragraph style={{ fontWeight: 500 }}>{t("create_new_voucher.summary.show_json")}:</Paragraph>
                    <Toggle type="button" onClick={() => setShowJson(!showJson)} active={showJson} />
                </Space>
                {showJson && (
                    <Editor
                        value={JSON.stringify(
                            {
                                transformedValues,
                            },
                            null,
                            2
                        )}
                        height="200px"
                        language="json"
                        options={{ readOnly: true }}
                    />
                )}
            </Space>
        </Space>
    );
};

export default CreateVoucherSummary;
