/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import CustomError from "../../../shared/CustomError";
import { ServerGameStatus } from "../../User/types";

interface LoadingState {
    loading: true;
    error: null;
    data: undefined;
}

interface ErrorState {
    loading: false;
    error: CustomError;
    data: null;
}

interface SuccessState<T> {
    loading: false;
    error: null;
    data: T;
}

interface SuccessPayload<T> {
    data: T;
}

interface LoadingPayload {
    loading: boolean;
}

interface ErrorPayload {
    error: CustomError;
}

export type ServerStatusState = LoadingState | ErrorState | SuccessState<ServerGameStatus>;

const initialState: LoadingState = {
    loading: true,
    error: null,
    data: undefined,
};

const serverStatusSlice = createSlice({
    name: "serverStatus",
    initialState: initialState as ServerStatusState,
    reducers: {
        setServerStatus: (
            state,
            { payload }: PayloadAction<SuccessPayload<Partial<ServerGameStatus>> | LoadingPayload | ErrorPayload>
        ) => {
            if ("loading" in payload) {
                state.loading = payload.loading;

                return;
            }

            if ("error" in payload) {
                state = {
                    loading: false,
                    error: payload.error,
                    data: null,
                };

                return;
            }

            state.loading = false;

            if ("data" in payload) {
                if (!payload.data) {
                    state.loading = false;
                    state.data = null;
                    return;
                }

                let hasChanges = false;
                const keys = Object.keys(payload.data);
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    if (
                        !state.data ||
                        payload.data[key as keyof ServerGameStatus] !== state.data[key as keyof ServerGameStatus]
                    ) {
                        hasChanges = true;
                        break;
                    }
                }

                if (hasChanges) {
                    Object.assign(state, {
                        ...state,
                        data: {
                            ...state.data,
                            ...payload.data,
                        },
                    });
                }
            }
        },
        clearServerStatus: (state) => {
            state.loading = true;
            state.error = null;
            state.data = undefined;
        },
    },
});

export const { setServerStatus, clearServerStatus } = serverStatusSlice.actions;

export default serverStatusSlice.reducer;
