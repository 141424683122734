import { useCallback, useMemo } from "react";
import { Category } from "../../../constants";
import { QueryParamsObject } from "../../../shared/types";
import usePreviewState from "../../Server/hooks/usePreviewState";
import { useSearchProjectQuery } from "../api";
import { MarketProject } from "../types";

export default function useMarketplace(filters: QueryParamsObject) {
    const [isPreview] = usePreviewState();

    const queryFilters = {
        ...filters,
        ...(isPreview && { project_type: "public" }),
    };

    const {
        data: projects,
        isLoading: projectsLoading,
        refetch,
        isFetching: isFetchingProjects,
    } = useSearchProjectQuery(queryFilters);

    const {
        data: packages,
        isLoading: packagesLoading,
        refetch: refetchPackages,
        isFetching: isFetchingPackages,
    } = useSearchProjectQuery({
        ...filters,
        labels: Category.SERVERPACK + (filters.labels ? `,${filters.labels}` : ""),
    });

    const projectFilter = useCallback(
        (project: MarketProject) => {
            if (isPreview) {
                return project.project_type === "public";
            }

            if (!project.latest_release) {
                return false;
            }

            return true;
        },
        [isPreview]
    );

    const finalProjects = useMemo(() => {
        return projects && projects.filter((project) => projectFilter(project));
    }, [projects, projectFilter]);

    const finalPackages = useMemo(() => {
        return packages && packages.filter((project) => projectFilter(project));
    }, [packages, projectFilter]);

    return {
        projects: finalProjects,
        projectsLoading,
        refetch,
        packages: finalPackages,
        isFetchingPackages,
        isFetchingProjects,
        packagesLoading,
        refetchPackages,
    };
}
