import { Button, InternalLink, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UIControls from "../UIControls";
import { ContentContainer, StyledHeader } from "./style";
import { Props } from "./types";

const CustomScreenTemplate: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <StyledHeader>
                <Space $padding={0} $gap={10} $direction="horizontal">
                    <Button title={t("custom_screen.back")} onClick={() => navigate(-1)}>
                        {t("custom_screen.back")}
                    </Button>
                    <InternalLink title={t("custom_screen.homepage")} to="/">
                        {t("custom_screen.homepage")}
                    </InternalLink>
                </Space>
                <Space $padding={0} $gap={10} $direction="horizontal">
                    <UIControls />
                </Space>
            </StyledHeader>
            <ContentContainer>{children}</ContentContainer>
        </>
    );
};

export default CustomScreenTemplate;
