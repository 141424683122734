import { FaLongArrowAltLeft } from "@react-icons/all-files/fa/FaLongArrowAltLeft";
import { FaPlay } from "@react-icons/all-files/fa/FaPlay";

import { Button, InternalLink, Paragraph, Space } from "csrvui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, To, useLocation } from "react-router-dom";
import { StyledLoader } from "../../../../../shared/components/PanelLoader/style";
import useTheme from "../../../../../shared/hooks/useCustomTheme";
import { useTrackEvent } from "../../../../../tracking/useTrackEvent";
import { useGetProductQuery } from "../../../../Billings/api";
import useCreateServer from "../../../../Server/hooks/useCreateServer";
import useUserData from "../../../../User/hooks/useUserData";
import useUserVouchers from "../../../../User/hooks/useUserVouchers";
import standarizeUrl from "../../../helpers/standarizeUrl";
import useProjectAssets from "../../../hooks/useProjectAssets";
import useVideo from "../../../hooks/useVideo";
import {
    GetMarketProjectResponse,
    MarketplaceCardOptions,
    MarketplaceProjectWithLabels,
    MarketProject,
} from "../../../types";
import MarketplaceSkeletonCard from "../../MarketplaceSkeletonCard";
import ProjectVisibilityChip from "../../ProjectVisibilityChip";
import Rating from "../../Rating";
import RedeemFreeServerModal from "../../RedeemFreeServerModal";
import {
    CardPlayer,
    CardPlayerLoader,
    CardThumbnail,
    MarketplaceProjectCard,
    MarketplaceProjectContent,
} from "./style";

interface Props {
    data?: MarketProject | MarketplaceProjectWithLabels;
    dataWithRating?: GetMarketProjectResponse;
    variant?: "default" | "showcase";
    ratioVariant?: "default" | "wide";
    to?: string;
    options?: MarketplaceCardOptions;
    useShortDescription?: boolean;
    onRatingClick?: () => void;
    onReturn?: To;
}

const MarketplaceCard = ({
    data,
    variant = "default",
    ratioVariant = "default",
    dataWithRating,
    onRatingClick,
    to,
    onReturn,
    useShortDescription,
    options,
}: Props) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const cardRef = useRef<HTMLAnchorElement | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);
    const { t } = useTranslation();
    const { userAlreadyTestedServer } = useUserVouchers();
    const { user } = useUserData();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { redeemFreeServer } = useCreateServer();
    const { data: amethystProduct, isLoading: isProductLoading } = useGetProductQuery("AMETHYST");
    const location = useLocation();
    const isServerMarketplace = location.pathname.startsWith("/s/");
    const canUserFreeServer = !userAlreadyTestedServer("AMETHYST") && !isServerMarketplace;
    const { thumbnail, gallery, video } = useProjectAssets(data);
    const { thumbnail: videoThumbnail } = useVideo(video, thumbnail);
    const thumbnailSource = options?.forceShowThumbUrl || thumbnail || videoThumbnail;
    const [currentThumbnail, setCurrentThumbnail] = useState(thumbnailSource);
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const { trackEvent } = useTrackEvent();
    const [shouldRenderVideo, setShouldRenderVideo] = useState(false);

    const hashToInstall =
        data && data.latest_release && data.latest_release.artifacts.find((el) => el.type === "kloceg")?.hash;

    const generatedRandomThumbnail = useMemo(() => {
        const newThumbnail = gallery[Math.floor(Math.random() * gallery.length)];
        return newThumbnail;
    }, [gallery]);

    useEffect(() => {
        if (video) return undefined;

        const setNewThumbnail = () => {
            let newThumbnail = generatedRandomThumbnail;
            if (newThumbnail === currentThumbnail && gallery.length > 1) {
                newThumbnail = generatedRandomThumbnail;
            }
            setCurrentThumbnail(newThumbnail);
        };

        if (isHovered) {
            setNewThumbnail();
            const interval = setInterval(setNewThumbnail, 2000);
            return () => clearInterval(interval);
        }
        setCurrentThumbnail(thumbnail || `https://placehold.co/100x100?text=${data ? data.name : ""}`);

        return undefined;
    }, [currentThumbnail, data, gallery.length, generatedRandomThumbnail, isHovered, thumbnail, video]);

    const handleLoadingStart = () => {
        setIsVideoLoading(true);
    };

    const handleLoadedData = () => {
        setIsVideoLoading(false);
    };

    const handleProgress = () => {
        const video = videoRef.current;

        if (!video) return;

        const { buffered } = video;
        const { duration } = video;

        if (duration > 0) {
            const loadedPercentage = (buffered.end(buffered.length - 1) / duration) * 100;

            if (loadedPercentage >= 10) {
                setIsVideoLoading(false);
            }
        }
    };

    useEffect(() => {
        const video = videoRef.current;

        if (!video) return undefined;

        video.addEventListener("progress", handleProgress);
        video.addEventListener("loadstart", handleLoadingStart);
        video.addEventListener("playing", handleLoadedData);

        return () => {
            video.removeEventListener("progress", handleProgress);
            video.removeEventListener("loadstart", handleLoadingStart);
            video.removeEventListener("playing", handleLoadedData);
        };
    }, [shouldRenderVideo]);

    const playVideo = () => {
        if (videoRef.current) {
            try {
                videoRef.current.load();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.debug("Error playing video:", error);
            }
        }
    };

    const handleMouseEnter = useCallback(async () => {
        setIsHovered(true);
        setShouldRenderVideo(true);

        await playVideo();
    }, []);

    // Mobile video handling
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsHovered(false);
                    if (videoRef.current) {
                        videoRef.current.pause();
                    }
                    setCurrentThumbnail(thumbnail || `https://placehold.co/100x100?text=${data ? data.name : ""}`);
                }

                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                    timeoutRef.current = null;
                }

                if (entry.intersectionRatio >= 1) {
                    timeoutRef.current = setTimeout(() => {
                        handleMouseEnter();
                        timeoutRef.current = null;
                    }, 1500);
                }
            },
            { threshold: [0, 1], rootMargin: "-100px 0px 0px 0px" }
        );

        const currentCardRef = cardRef.current;

        if (currentCardRef && window.innerWidth < 700) {
            observer.observe(currentCardRef);
        }

        return () => {
            if (currentCardRef) {
                observer.unobserve(currentCardRef);
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [handleMouseEnter, thumbnail, data]);
    // End of mobile video handling

    if (!data) {
        return <MarketplaceSkeletonCard />;
    }

    const handleMouseLeave = async () => {
        setIsHovered(false);

        if (videoRef.current) {
            videoRef.current.pause();
        }

        setCurrentThumbnail(thumbnail || `https://placehold.co/100x100?text=${data.name}`);
    };

    if (isProductLoading) {
        return null;
    }

    if (variant === "showcase") {
        return (
            <MarketplaceProjectCard
                variant={ratioVariant}
                style={{
                    height: "100%",
                }}
            >
                <Space style={{ zIndex: 1 }} $direction="horizontal" $fullWidth $align="space-between">
                    <InternalLink
                        data-ph-capture-attribute-id="marketplace_card:return_link"
                        data-ph-capture-attribute-return-link={onReturn}
                        style={{
                            padding: "10px 20px",
                            backgroundColor: theme.colors.primaryBackground,
                            color: theme.colors.dark,
                            boxShadow: ` 0px 2px 2px ${theme.colors.dark}`,
                        }}
                        $variant="primary"
                        $filled
                        $underline={false}
                        to={onReturn || ""}
                    >
                        <Space $direction="horizontal" $align="center" $oppositeAlign="center" $padding={0}>
                            <Space $padding={0}>
                                <FaLongArrowAltLeft />
                            </Space>
                            <Paragraph style={{ fontWeight: 550 }}>{t("common.back")}</Paragraph>
                        </Space>
                    </InternalLink>
                    {dataWithRating && <Rating onClick={onRatingClick} project={dataWithRating} />}
                </Space>
                <CardThumbnail show={!video || !!(options && options.forceShowThumbUrl)}>
                    <source
                        media="(max-width: 649px)"
                        srcSet={standarizeUrl(thumbnailSource, {
                            width: 700,
                            placeholder: data.name,
                        })}
                    />
                    <source
                        media="(min-width: 650px)"
                        srcSet={standarizeUrl(thumbnailSource, {
                            width: 1000,
                            placeholder: data.name,
                        })}
                    />
                    <img
                        src={standarizeUrl(thumbnailSource, {
                            width: 1000,
                            placeholder: data.name,
                        })}
                        alt={data.name}
                    />
                </CardThumbnail>

                <CardPlayerLoader show={isVideoLoading && !!video}>
                    <StyledLoader />
                </CardPlayerLoader>

                {!(options && options.forceShowThumbUrl) && (
                    <CardPlayer
                        onClick={() => {
                            trackEvent("marketplace", "video", "click", {
                                slug: data.slug,
                            });
                        }}
                        show={!!video}
                        autoPlay
                        loop
                        muted
                    >
                        <source src={video} type="video/mp4" />
                    </CardPlayer>
                )}

                <MarketplaceProjectContent>
                    {" "}
                    <Paragraph
                        $level={1}
                        style={{
                            fontWeight: "500",
                            color: theme.colors.secondaryText,
                            marginTop: "5px",
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        {data.name}

                        <ProjectVisibilityChip variant="icon" project={data} />
                    </Paragraph>
                    {useShortDescription && (
                        <Paragraph $level={3} style={{ color: theme.colors.secondaryText, marginTop: "5px" }}>
                            {data.short_description}
                        </Paragraph>
                    )}
                </MarketplaceProjectContent>
            </MarketplaceProjectCard>
        );
    }

    return (
        <>
            {" "}
            {hashToInstall && amethystProduct && (
                <RedeemFreeServerModal
                    product={amethystProduct}
                    onClose={() => setIsModalOpen(false)}
                    open={isModalOpen}
                    preinstallProject={`${data.slug}:${hashToInstall}`}
                />
            )}
            <Link
                data-ph-capture-attribute-id="marketplace_card:link"
                data-ph-capture-attribute-slug={data.slug}
                data-ph-capture-attribute-link={to}
                replace
                to={to || ""}
                reloadDocument
                ref={cardRef}
                style={{
                    width: "100%",
                    height: "auto",
                    textDecoration: "none",
                }}
            >
                <MarketplaceProjectCard
                    // image={}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {/* Temporarily disabled */}
                    {/* {showBadge && <MarketplaceProjectBadge>Popularne</MarketplaceProjectBadge>} */}
                    <CardThumbnail show={isVideoLoading || !isHovered || !video}>
                        <source
                            media="(max-width: 649px)"
                            srcSet={standarizeUrl(thumbnailSource, {
                                width: 250,
                                placeholder: data.name,
                            })}
                        />
                        <source
                            media="(min-width: 650px)"
                            srcSet={standarizeUrl(thumbnailSource, {
                                width: 460,
                                placeholder: data.name,
                            })}
                        />
                        <img
                            loading="lazy"
                            src={standarizeUrl(thumbnailSource, {
                                width: 460,
                                placeholder: data.name,
                            })}
                            alt={data.name}
                        />
                    </CardThumbnail>

                    <CardPlayerLoader show={isVideoLoading && !!video && isHovered}>
                        <StyledLoader />
                    </CardPlayerLoader>

                    {shouldRenderVideo && !!video && (
                        <CardPlayer show={isHovered} ref={videoRef} loop autoPlay muted>
                            <source src={video} type="video/mp4" />
                        </CardPlayer>
                    )}
                    <Space style={{ zIndex: 0 }} $direction="horizontal" $fullWidth $align="end">
                        <Button
                            $variant="success"
                            disabled={!hashToInstall}
                            $disabledText={t("marketplace.no_releases")}
                            style={{
                                padding: "10px 10px",
                                boxShadow: ` 0px 2px 10px ${theme.colors.success}`,
                            }}
                            onClick={(e) => {
                                if (amethystProduct && canUserFreeServer && user) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    redeemFreeServer(amethystProduct, true, {
                                        engine: `${data.slug}:${hashToInstall}`,
                                    });
                                }
                                if (amethystProduct && canUserFreeServer && !user) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setIsModalOpen(true);
                                }
                            }}
                        >
                            <Space
                                $direction="horizontal"
                                $align="center"
                                $oppositeAlign="center"
                                $gap={15}
                                $padding={0}
                            >
                                <FaPlay size={19} />
                                <Paragraph
                                    $level={4}
                                    style={{
                                        fontWeight: 550,
                                        color: theme.colors.secondaryText,
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {!canUserFreeServer ? t("marketplace.run") : t("marketplace.run_free")}
                                </Paragraph>
                            </Space>
                        </Button>
                    </Space>
                    <MarketplaceProjectContent>
                        {/* <Space $direction="horizontal" $padding={0}>
                        {exampleLabels.map((label) => (
                            <Chip
                                key={label}
                                bgColor={theme.colors.primaryBackground}
                                style={{
                                    borderRadius: "4px",
                                    width: "fit-content",
                                }}
                                textProps={{
                                    style: {
                                        fontWeight: 550,
                                        width: "100%",
                                        display: "flex",
                                    },
                                }}
                                textColor={theme.colors.dark}
                            >
                                <Space $padding={0}>
                                    <IoMdDownload />
                                </Space>
                                {label}
                            </Chip>
                        ))}
                    </Space> */}
                        <Paragraph
                            style={{
                                display: "inline-flex",
                                gap: "10px",
                                alignItems: "center",
                                fontWeight: "500",
                                color: theme.colors.secondaryText,
                                marginTop: "5px",
                                fontSize: "24px",
                            }}
                        >
                            {data.name}
                            <ProjectVisibilityChip variant="icon" project={data} />
                        </Paragraph>
                        {useShortDescription && (
                            <Paragraph
                                style={{ color: theme.colors.secondaryText, marginTop: "5px", fontSize: "20px" }}
                            >
                                {data.short_description}
                            </Paragraph>
                        )}
                    </MarketplaceProjectContent>
                </MarketplaceProjectCard>
            </Link>
        </>
    );
};

export default MarketplaceCard;
