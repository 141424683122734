export interface ErrorFields {
    resource?: string;
}

export default class CustomError extends Error {
    event_id?: string;

    fields?: ErrorFields;

    error?: string;

    constructor(message?: string, event_id?: string) {
        super(message);
        this.event_id = event_id;
    }
}
