import { CsrvProcessState, CsrvProcessType } from "@craftserve/csrvprocess";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks";
import { useLazyServerByIdQuery } from "../../Server/api";
import { useLazyGetBackupsQuery } from "../../Server/modules/Backups/api";
import { Process } from "../types";
import useUserData from "./useUserData";
import useWallets from "./useWallets";

export default function (process: Process) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [refreshBackups] = useLazyGetBackupsQuery();

    const [label, setLabel] = useState<string | undefined>();
    const [state, setState] = useState("");
    const [withProgress, setWithProgress] = useState(false);

    const [trigger] = useLazyServerByIdQuery();
    const { refreshWallets } = useWallets();
    const { updateUserData } = useUserData();

    const typeLabels = useMemo(
        () => ({
            [CsrvProcessType.PREPARING_SERVER]: t("processes.preparing_server_label"),
            [CsrvProcessType.UPLOADING_FILES]: t("processes.uploading_files_label"),
            [CsrvProcessType.UPLOADING_FILES_FROM_URL]: t("processes.uploading_files_label"),
            [CsrvProcessType.STARTING_GAME]: t("processes.starting_server_label"),
            [CsrvProcessType.STOPPING_GAME]: t("processes.stopping_server_label"),
            [CsrvProcessType.CREATING_SERVER_POD]: t("processes.creating_instance_server_label"),
            [CsrvProcessType.SERVER_BACKUP]: t("processes.server_backup_label"),
            [CsrvProcessType.SERVER_BACKUP_DELETION]: t("processes.server_backup_deletion_label"),
            [CsrvProcessType.SERVER_RESTORE]: t("processes.server_restore_label"),
            [CsrvProcessType.SERVER_FORMAT]: t("processes.server_format_label"),
            [CsrvProcessType.SERVER_INSTALL_PACKAGE]: t("processes.server_install_package_label"),
            [CsrvProcessType.SERVER_UNINSTALL_PACKAGE]: t("processes.server_uninstall_package_label"),
            [CsrvProcessType.SERVER_MIGRATION]: t("processes.server_migration_label"),
            [CsrvProcessType.SERVER_CLEANUP]: t("processes.server_cleanup_label"),
            [CsrvProcessType.EXTRACTING_ARCHIVE_STATUS]: t("processes.server_extracting_archive"),

            [CsrvProcessType.TRANSACTION_STATUS]: t("processes.transaction_status_label"),
            [CsrvProcessType.EMAIL_CHANGE_STATUS]: t("processes.email_change_status_label"),
            [CsrvProcessType.PASSWORD_CHANGE_STATUS]: t("processes.password_change_status_label"),
            [CsrvProcessType.PASSWORD_RESET_STATUS]: t("processes.password_reset_status_label"),
            [CsrvProcessType.SERVER_RENEW_STATUS]: t("processes.server_renew_status_label"),
            [CsrvProcessType.DOMAIN_SET_STATUS]: t("processes.domain_set_status_label"),
            [CsrvProcessType.PAY_FOR_SERVER_STATUS]: t("processes.pay_for_server_status_label"),
            [CsrvProcessType.AUTHORIZATION_STATUS]: t("processes.authorization_status_label"),
            [CsrvProcessType.SERVER_CREATE_STATUS]: t("processes.server_create_status_label"),
            [CsrvProcessType.VOUCHER_ADD_STATUS]: t("processes.voucher_add_status_label"),
            [CsrvProcessType.CHANGE_SERVER_NAME_STATUS]: t("processes.change_server_name_status_label"),
            [CsrvProcessType.CREATING_FOLDER_STATUS]: t("processes.creating_folder_status_label"),
            [CsrvProcessType.CREATING_FILE_STATUS]: t("processes.creating_file_status_label"),
            [CsrvProcessType.MARKETPLACE_RELEASE_UPLOAD]: t("processes.marketplace_release_upload_label"),
            [CsrvProcessType.SERVER_CHANGE_ENGINE]: t("processes.server_change_engine_label"),
            [CsrvProcessType.SERVER_PACKAGE_CREATE]: t("processes.server_package_create_label"),
        }),
        [t]
    );

    const stateLabels = useMemo(
        () => ({
            [CsrvProcessState.ABORTED]: t("processes.aborted_state"),
            [CsrvProcessState.DONE]: t("processes.done_state"),
            [CsrvProcessState.FAILED]: t("processes.failed_state"),
            [CsrvProcessState.IN_PROGRESS]: t("processes.in_progress_state"),
            [CsrvProcessState.TIMEOUT]: t("processes.timeout_state"),
        }),
        [t]
    );

    useEffect(() => {
        setState(stateLabels[process.state as CsrvProcessState]);
        if (process.type in typeLabels) {
            setLabel(typeLabels[process.type]);
        } else {
            setLabel(process.label);
        }
        switch (process.type) {
            case CsrvProcessType.UPLOADING_FILES:
            case CsrvProcessType.UPLOADING_FILES_FROM_URL:
            case CsrvProcessType.SERVER_FORMAT:
                setWithProgress(true);
                setLabel(typeLabels[process.type]);
                break;
            case CsrvProcessType.SERVER_BACKUP:
            case CsrvProcessType.SERVER_BACKUP_DELETION:
            case CsrvProcessType.SERVER_RESTORE:
                setWithProgress(true);
                setLabel(typeLabels[process.type]);
                if (process.state === "DONE") {
                    refreshBackups(process.resourceId);
                }

                break;
            case CsrvProcessType.CREATING_SERVER_POD:
            case CsrvProcessType.PREPARING_SERVER:
            case CsrvProcessType.STARTING_GAME:
            case CsrvProcessType.STOPPING_GAME:
            case CsrvProcessType.SERVER_UNINSTALL_PACKAGE:
            case CsrvProcessType.SERVER_INSTALL_PACKAGE:
                setWithProgress(false);
                setLabel(typeLabels[process.type]);

                if (process.state === "DONE") {
                    trigger(process.resourceId);
                    refreshWallets();
                    updateUserData();
                }
                break;

            default:
                setWithProgress(false);
                setLabel(typeLabels[process.type]);
                break;
        }
    }, [
        dispatch,
        navigate,
        process,
        refreshBackups,
        refreshWallets,
        stateLabels,
        trigger,
        typeLabels,
        updateUserData,
        withProgress,
    ]);

    return { state, label, withProgress };
}
