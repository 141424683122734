import styled from "styled-components";

export const StyledHeader = styled.header`
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    min-height: 100vh;
`;
