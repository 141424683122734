import {
    Infer,
    any,
    array,
    boolean,
    date,
    enums,
    instance,
    nullable,
    number,
    object,
    optional,
    record,
    string,
} from "superstruct";

import { CsrvProcessMessage, CsrvProcessState, CsrvProcessType } from "@craftserve/csrvprocess";
import { Money } from "ts-money";
import { CountryTaxRate, CreatedAt, EventLogWithoutData, FetchingStatus, ProcessID, UserID } from "../../shared/types";
import { BillingService } from "../Billings/types";
import { RequestedBillingServices, VoucherUseWithVoucher } from "../Server/types";

export interface State {
    sessionVerified?: boolean;
    user?: User | null;
    errorMessage?: string | null;
    wallets: {
        data: Wallet[];
        status: FetchingStatus;
        error: string | null;
    };
    notifications: {
        data: {
            notifications: Notification[];
            count: number;
        };
        status: FetchingStatus;
        error: string | null;
    };
    processes: CsrvProcessMessage[];
}

export interface CheckAvailabilityUsernamePayload {
    nickname: string;
}

export const NotificationType = enums([
    "SERVICE_EXPIRES_WARNING",
    "SERVER_RENEWED",
    "SERVER_CREATED",
    "SERVER_EXPIRED",
    "SERVER_CLEANED_UP",
    "SERVER_TESTING_ENABLED",
    "SERVER_CLEANUP_WARNING",
    "SERVER_EXPIRES_WARNING",
    "SERVER_CRASHED",
    "SERVER_MIGRATED",
    "SERVER_PLAYER_JOINED",
    "SERVER_PLAYER_TRIED_TO_JOIN",
    "SERVER_TESTING_CODE",
    "ACCOUNT_EMAIL_CONFIRM",
    "ACCOUNT_RESET_PASSWORD",
    "ACCOUNT_FAST_REGISTER",
    "ACCOUNT_FAST_LOGIN",
]);

export const Notification = object({
    id: string(), // values.NotificationId
    users: array(string()), // []values.UserID
    type: string(), // values.NotificationType
    status: string(), // values.NotificationStatus
    campaign: nullable(string()), // *string
    version: nullable(string()), // *string
    fields: record(string(), any()), // map[string]interface{}
    receivers: array(string()), // values.Receivers
    actions: nullable(record(string(), any())), // json.RawMessage
    action_expires: nullable(date()), // *time.Time
    created_at: date(), // time.Time
    opened_at: nullable(date()), // *time.Time
});

export type Notification = Infer<typeof Notification>;
export interface AuthReducer {
    verified: boolean;
    user: User | null;
    isLoading: boolean;
    error: string | null;
    isAdmin: boolean;
}

const TaxData = object({
    name: optional(string()),
    short_name: nullable(string()),
    nip: optional(string()),
    address: optional(string()),
    postal_code: optional(string()),
    city: optional(string()),
    country: optional(string()),
    updated_at: nullable(date()),
    language: optional(string()),
    tax_id: nullable(string()),
});

export const User = object({
    id: string(), // values.UserID
    username: string(), // values.Username
    email: string(), // values.Email
    language: string(), // values.Language
    created_at: date(), // time.Time
    destroyed_at: nullable(date()), // *time.Time
    zendesk_id: nullable(number()), // *int64
    domains_user_id: nullable(number()), // *int
    accepted_cookie_policy: boolean(), // bool
    gdpr_consent: boolean(), // bool
    accepted_tos: boolean(), // bool
    newsletter: nullable(date()), // *time.Time
    email_verified: nullable(date()), // *time.Time
    user_capabilities: record(string(), any()), // map[values.CapabilityType]any
    unread_notifications: number(), // int64
    services: array(BillingService), // []PublicBillingService
    tax_data: nullable(TaxData), // *json.RawMessage
    voucher_uses: array(VoucherUseWithVoucher), // []PublicVoucherUseWithVoucher
});

export const TaxInfoResponse = object({
    country_code: string(),
    vat_rate: number(),
});

export type TaxInfoResponse = Infer<typeof TaxInfoResponse>;

export type User = Infer<typeof User>;

export const GetUserNotificationsResponse = object({
    notifications: array(Notification),
    count: number(),
});
export type GetUserNotificationsResponse = Infer<typeof GetUserNotificationsResponse>;

export const GetEventLogsResponse = object({
    data: array(EventLogWithoutData),
    count: number(),
});

export type GetEventLogsResponse = Infer<typeof GetEventLogsResponse>;
export interface LocalLoginPayload {
    email: string;
    password: string;
}

export enum GameStatus {
    IRL_NEW = "IRL_NEW",
    IRL_RUNNING = "IRL_RUNNING",
    IRL_IDLE = "IRL_IDLE",
    IRL_LIGHT_SLEEP = "IRL_LIGHT_SLEEP",
    IRL_DEEP_SLEEP = "IRL_DEEP_SLEEP",
    IRL_HIBERNATED = "IRL_HIBERNATED",
    IRL_TERMINATED = "IRL_TERMINATED",
}

export const ServerGameStatus = object({
    game_status: nullable(enums(Object.values(GameStatus))),
    pod_name: nullable(string()),
    pod_image: nullable(string()),
    hypnos_version: nullable(string()),
    launched: nullable(date()),
});

export type ServerGameStatus = Infer<typeof ServerGameStatus>;

export type ServerStatusResponse = Infer<typeof ServerGameStatus>;
export interface RegisterPayload {
    email: string;
    password: string;
    language: string;
    newsletter: boolean;
    currency: string;
}

export interface Log {
    id: number;
    user_id: UserID;
    created_at: CreatedAt;
    action: string;
    data: object;
    success: boolean;
}

export interface NotificationsResponse {
    notifications: Notification[];
    count: number;
    after: string | null;
}

export const Wallet = object({
    id: string(), // values.WalletId
    currency: string(), // monies.CurrencyCode
    balance: instance(Money), // monies.Money
    details: record(string(), any()), // json.RawMessage
    created_at: date(), // time.Time
    country_tax_rate: CountryTaxRate, // values.CountryTaxRate
});

export type Wallet = Infer<typeof Wallet>;

export const WalletServer = object({
    id: string(),
    name: string(),
});

export type WalletServer = Infer<typeof WalletServer>;

export const WalletWithServers = object({
    wallet: Wallet, // PublicWallet
    servers: array(WalletServer), // []PublicWalletServer
});

export type WalletWithServers = Infer<typeof WalletWithServers>;
export interface ChangeEmailPayload {
    email: string;
    password: string;
}
export interface InvoiceFormPayload {
    name: string;
    short_name?: string;
    address: string;
    country: string;
    city: string;
    postal_code: string;
    tax_id?: string;
    language: string;
    email: string;
}

export interface ChangePasswordPayload {
    oldPassword: string;
    newPassword: string;
}

export interface Process {
    id: ProcessID;
    type: CsrvProcessType;
    state: CsrvProcessState;
    label: string;
    resourceId: string;
    progress?: number;
    data?: Record<string, unknown>;
}

export interface ProcessesState {
    processes: Process[];
}

export interface EventsQueryData {
    id: string;
    action?: string;
    fromDate?: string;
    toDate?: string;
    after?: string;
}

export interface UserNotificationsQueryData {
    id: string;
    type?: string;
    after?: string;
}

export interface PackagesSearchQueryData {
    name: string;
    tag: string;
    labels: {
        [key: string]: string;
    };
}

type ProcessExecutionType = string;
type ProcessResourceType = string;

export interface CreateProcessRemotelyPayload {
    process_type: CsrvProcessType;
    execution_type: ProcessExecutionType;
    data: Record<string, unknown>;
    remote_id?: string;
    resource_id: string;
    resource_type: ProcessResourceType;
    cancellable: boolean;
    deadline: string;
    timeout: string;
}

const FastAuthServer = object({
    cluster_id: string(),
    game: string(),
    requested_billing_services: array(object({})),
    billing_period: string(),
    currency: string(),
});

const FastAuthBeginPayload = object({
    email: string(),
    language: string(),
    server: optional(FastAuthServer),
});

export type FastAuthBeginPayload = Infer<typeof FastAuthBeginPayload>;

const FastAuthCompletePayload = object({
    code: string(),
    terms: boolean(),
    language: string(),
    currency: string(),
    newsletter: boolean(),
});

export type FastAuthCompletePayload = Infer<typeof FastAuthCompletePayload>;

const FastAuthCompleteResponse = object({
    user: User,
    already_exists: boolean(),
    server: object({
        cluster_id: string(),
        game: string(),
        requested_billing_services: array(RequestedBillingServices),
        billing_period: string(),
        currency: string(),
    }),
    voucher: string(),
});

export type FastAuthCompleteResponse = Infer<typeof FastAuthCompleteResponse>;
