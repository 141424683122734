import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentScope } from "@sentry/browser";
import { array } from "superstruct";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { transformError } from "../../shared/helpers/errors";
import { SupportedProducts } from "../Server/constants";
import * as ServerTypes from "../Server/types";
import { BillingServicesWithCost } from "../Server/types";
import * as BillingsTypes from "./types";

export const api = createApi({
    reducerPath: "products",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/billings`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            const scope = getCurrentScope();
            if (scope) {
                scope.setTag("transaction_id", transactionId);
            }
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getServiceById: builder.query<BillingsTypes.BillingService, string>({
            query: (id) => ({
                url: `/services/${id}`,
                credentials: "include",
                method: "GET",
                validateStatus: (response) => response.status === 200,
            }),
            transformResponse: (response: BillingsTypes.BillingService) => {
                return transformToStruct(response, BillingsTypes.BillingService, "/services/:id");
            },
            transformErrorResponse: transformError,
        }),
        getProducts: builder.query<BillingsTypes.BillingProduct[], void>({
            query: () => ({
                url: "/products",
                validateStatus: (response) => response.status === 200,
            }),
            transformResponse: (response: BillingsTypes.BillingProduct[]) => {
                const supportedProducts = Object.values(SupportedProducts);

                const sorted = response
                    .filter((el) => el.prices !== null && supportedProducts.includes(el.id as SupportedProducts))
                    .sort((a, b) => {
                        const aPrice = a.prices?.find(
                            (price) => price.period === "720h" && price.gross_price.currency === "PLN"
                        );
                        const bPrice = b.prices?.find(
                            (price) => price.period === "720h" && price.gross_price.currency === "PLN"
                        );

                        if (!aPrice || !bPrice) {
                            return 0;
                        }

                        return aPrice.gross_price.amount >= bPrice.gross_price.amount ? 1 : -1;
                    });
                return transformToStruct(sorted, array(BillingsTypes.BillingProduct), "/products");
            },
            transformErrorResponse: transformError,
        }),
        getProduct: builder.query<BillingsTypes.BillingProduct, string>({
            query: (id) => ({
                url: `/products/${id}`,
                credentials: "include",
                method: "GET",
                validateStatus: (response) => response.status === 200,
            }),
            transformResponse: (response: BillingsTypes.BillingProduct) => {
                return transformToStruct(response, BillingsTypes.BillingProduct, "/products/:id");
            },
            transformErrorResponse: transformError,
        }),
        getServiceCost: builder.query<BillingServicesWithCost, { id: string; withVoucher?: string }>({
            query: ({ id, withVoucher }) => ({
                url: `/services/${id}/cost`,
                credentials: "include",
                method: "GET",
                params: {
                    vouchers: withVoucher,
                },
                validateStatus: (response) => response.status === 200,
            }),
            transformResponse: (response: BillingServicesWithCost) => {
                return transformToStruct(response, BillingServicesWithCost, "/services/:id/cost");
            },
            transformErrorResponse: transformError,
        }),

        // get billing product cost
        getBillingServiceCost: builder.query<
            BillingsTypes.BillingProduct,
            { vouchers: string; currency: string; id: string }
        >({
            query: ({ currency, vouchers, id }) => ({
                url: `/products/${id}/cost`,
                credentials: "include",
                method: "GET",
                params: {
                    vouchers,
                    currency,
                },
                validateStatus: (response) => response.status === 200,
            }),
            transformResponse: (response: BillingsTypes.BillingProduct) => {
                return transformToStruct(response, BillingsTypes.BillingProduct, "/services/:id/cost");
            },
            transformErrorResponse: transformError,
        }),
        verifyVoucher: builder.mutation<ServerTypes.Voucher, string>({
            query: (id) => ({
                url: `/vouchers/${id}`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: ServerTypes.Voucher) => {
                return transformToStruct(response, ServerTypes.Voucher, "/vouchers/:id");
            },
            transformErrorResponse: transformError,
        }),
        verifyVoucherByService: builder.mutation<ServerTypes.Voucher, { serviceId: string; id: string }>({
            query: ({ serviceId, id }) => ({
                url: `/services/${serviceId}/vouchers/${id}`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: ServerTypes.Voucher) => {
                return transformToStruct(response, ServerTypes.Voucher, "/services/:serviceid/vouchers/:id");
            },
            transformErrorResponse: transformError,
        }),
        activeVouchers: builder.query<ServerTypes.VoucherUseWithVoucher[], string>({
            query: (serviceId) => ({
                url: `/services/${serviceId}/vouchers`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: ServerTypes.VoucherUseWithVoucher[]) => {
                return transformToStruct(
                    response,
                    array(ServerTypes.VoucherUseWithVoucher),
                    "/services/:serviceId/vouchers"
                );
            },
            transformErrorResponse: transformError,
        }),
    }),
});

export const {
    useGetServiceByIdQuery,
    useLazyGetServiceByIdQuery,
    useGetProductsQuery,
    useGetProductQuery,
    useGetServiceCostQuery,
    useLazyGetServiceCostQuery,
    useVerifyVoucherMutation,
    useLazyGetBillingServiceCostQuery,
    useVerifyVoucherByServiceMutation,
    useActiveVouchersQuery,
} = api;
