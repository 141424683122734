import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "../../hooks";
import { usePingServerMutation } from "../../modules/Server/api";
import { getServerStatus } from "../../modules/Server/slices/selectors";
import { GameStatus } from "../../modules/User/types";
import debug from "./debug";

const PING_INTERVAL = 30000;

const usePingServer = (serverId: string | undefined) => {
    const [pingServer] = usePingServerMutation();
    const status = useAppSelector(getServerStatus);
    const gameStatus = useMemo(() => {
        if (status.loading) {
            return undefined;
        }

        if (status.error) {
            return null;
        }

        return status.data.game_status;
    }, [status]);

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const startPinging = useCallback(() => {
        if (!serverId || intervalRef.current) return;

        // Send an initial ping
        if (gameStatus === GameStatus.IRL_RUNNING) {
            debug("Start pinging server");
            pingServer(serverId);
            debug("Initial server ping sent");
        }

        intervalRef.current = setInterval(() => {
            if (gameStatus === GameStatus.IRL_RUNNING) {
                pingServer(serverId);
                debug("Server ping sent");
            }
        }, PING_INTERVAL);
    }, [gameStatus, pingServer, serverId]);

    const stopPinging = useCallback(() => {
        if (intervalRef.current) {
            debug("Stop pinging server");
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (!serverId) return undefined;

        const handleVisibilityChange = () => {
            if (document.hidden) {
                stopPinging();
            } else {
                startPinging();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        if (!document.hidden) {
            startPinging();
        }

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            stopPinging();
        };
    }, [startPinging, stopPinging, serverId]);

    useEffect(() => {
        return () => {
            stopPinging();
        };
    }, [stopPinging]);

    return null;
};

export default usePingServer;
