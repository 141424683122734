import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetServerByDomainMutation, useLazyServerByIdQuery } from "../../modules/Server/api";
import { CheckAvailabilityDomainPayload } from "../../modules/Server/types";
import { ZoneName } from "../../rootTypes";

const useCurrentServerIdentitites = (): {
    serverIdentity: string | undefined;
    serverId: string | undefined;
    checked: boolean;
} => {
    const { serverIdentity } = useParams<{ serverIdentity: string }>();
    const [serverId, setServerId] = useState<string | undefined>(undefined);
    const [getServerByDomain] = useGetServerByDomainMutation();
    const [getServerById] = useLazyServerByIdQuery();
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    /**
     * Retrieves the server ID associated with a given domain identity.
     *
     * @param identity - The server identity to check. (expected to be a domain name)
     * @returns A Promise that resolves to the server ID as a string.
     * @throws An error if the server ID is not found in the response.
     */
    const getServerIdByDomain = useCallback(
        async (identity: string): Promise<string> => {
            const payload: CheckAvailabilityDomainPayload = {
                subdomain: identity,
                zone_name: ZoneName,
            };
            const response = await getServerByDomain(payload).unwrap();
            if (response && response.server_id) {
                return response.server_id;
            }
            throw new Error("Server ID not found in response");
        },
        [getServerByDomain]
    );

    /**
     * Checks if the given identity is a server ID.
     *
     * @param identity - The identity to check.
     * @returns A Promise that resolves to a boolean indicating if the identity is a server ID.
     */
    const checkIfIdentityIsServerId = useCallback(
        async (identity: string): Promise<boolean> => {
            const response = await getServerById(identity).unwrap();
            if (response && response.id) {
                return true;
            }
            return false;
        },
        [getServerById]
    );

    /**
     * Validates the server identity and sets the server ID accordingly.
     */
    const validateServerIdentity = useCallback(async () => {
        if (!serverIdentity) {
            setServerId(undefined);
            setChecked(true);
            return;
        }

        try {
            if (serverIdentity) {
                const serverId = await getServerIdByDomain(serverIdentity);
                setServerId(serverId);
            }
        } catch (err) {
            try {
                const isServerId = await checkIfIdentityIsServerId(serverIdentity);
                if (isServerId) {
                    setServerId(serverIdentity);
                } else {
                    setServerId(undefined);
                    navigate("/404");
                }
            } catch (err) {
                setServerId(undefined);
                navigate("/404");
            }
        }

        setChecked(true);
    }, [serverIdentity, getServerIdByDomain, checkIfIdentityIsServerId, navigate]);

    useEffect(() => {
        validateServerIdentity();
    }, [validateServerIdentity]);

    return { serverIdentity, serverId, checked };
};

export default useCurrentServerIdentitites;
