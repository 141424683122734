import { Button, Card, Label, Paragraph, Space, TextInput } from "csrvui";
import { Field, FieldProps, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import formatFormikError from "../../../../shared/helpers/formatFormikError";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { CreateNewVoucherFormValues } from "../../types";
import CreateVoucherSummary from "../CreateVoucherSummary";
import VoucherActionManager from "../VoucherActionManager";
import { FormWrapper, InputGroupWrapper } from "./styles";

interface Props {
    onSubmit: (values: CreateNewVoucherFormValues, resetForm: () => void) => void;
}

const CreateVoucherForm = ({ onSubmit }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const validationSchema = Yup.object().shape({
        length: Yup.number()
            .min(1, t("create_new_voucher.validation.min_length"))
            .required(t("create_new_voucher.validation.required")),
        prefix: Yup.string(),

        charset: Yup.string()
            .required(t("create_new_voucher.validation.required"))
            .min(5, t("create_new_voucher.validation.min_charset")),
        expires: Yup.date().min(new Date(), t("create_new_voucher.validation.future_date")),
        group_id: Yup.string(),
        uses: Yup.number()
            .min(1, t("create_new_voucher.validation.min_uses"))
            .required(t("create_new_voucher.validation.required")),
        per_user: Yup.number()
            .min(1, t("create_new_voucher.validation.min_per_user"))
            .required(t("create_new_voucher.validation.required")),
        actions: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        coupon: Yup.object().nullable(),
                        add_hours: Yup.string().nullable(),
                        wallet_tx: Yup.array()
                            .of(
                                Yup.object().shape({
                                    amount: Yup.number()
                                        .required(t("create_new_voucher.validation.wallet_tx_amount_required"))
                                        .min(100, t("create_new_voucher.validation.wallet_tx_amount_min")),
                                    currency: Yup.string().required(
                                        t("create_new_voucher.validation.wallet_tx_currency_required")
                                    ),
                                })
                            )
                            .nullable(),
                    })
                    .test(
                        "at-least-one-field",
                        t("create_new_voucher.validation.at_least_one_field"),
                        (value) =>
                            !!value.coupon ||
                            !!value.add_hours ||
                            (Array.isArray(value.wallet_tx) && value.wallet_tx.length > 0)
                    )
            )
            .min(1, t("create_new_voucher.validation.min_actions")),
    });

    const initialValues: CreateNewVoucherFormValues = {
        length: 16,
        prefix: "",
        charset: "",
        expires: "",
        group_id: "",
        uses: 1,
        per_user: 1,
        actions: [],
    };

    const generateExampleVoucher = (values: CreateNewVoucherFormValues) => {
        const charset = values.charset.split("");
        const code = Array.from(
            { length: values.length },
            () => charset[Math.floor(Math.random() * charset.length)]
        ).join("");
        return !values.prefix ? code : `${values.prefix}-${code}`;
    };

    const predefinedCharsets = [
        {
            label: t("create_new_voucher.predefinedCharsets.AZ09"),
            value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        },
        {
            label: t("create_new_voucher.predefinedCharsets.AZ"),
            value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        },
        {
            label: t("create_new_voucher.predefinedCharsets.09"),
            value: "0123456789",
        },
    ];

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values, resetForm);
            }}
        >
            {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                <Form>
                    <FormWrapper>
                        <Card $maxWidth={780} title={t("create_new_voucher.title")}>
                            <Space>
                                <InputGroupWrapper>
                                    <Space $fullWidth $padding={0}>
                                        <Label htmlFor="length">{t("create_new_voucher.fields.length")}</Label>
                                        <Field id="length" name="length">
                                            {({ field }: FieldProps) => (
                                                <TextInput
                                                    {...field}
                                                    type="number"
                                                    $fullWidth
                                                    error={errors.length}
                                                    $hasError={!!errors.length && !!touched.length}
                                                    placeholder={t("create_new_voucher.fields.length_placeholder")}
                                                />
                                            )}
                                        </Field>
                                    </Space>
                                    <Space $fullWidth $padding={0}>
                                        <Label htmlFor="prefix">{t("create_new_voucher.fields.prefix")}</Label>
                                        <Field id="prefix" name="prefix">
                                            {({ field }: FieldProps) => (
                                                <TextInput
                                                    {...field}
                                                    type="text"
                                                    $fullWidth
                                                    error={errors.prefix}
                                                    $hasError={!!errors.prefix && !!touched.prefix}
                                                    placeholder={t("create_new_voucher.fields.prefix_placeholder")}
                                                />
                                            )}
                                        </Field>
                                    </Space>
                                </InputGroupWrapper>
                                <Space $padding={0} style={{ marginBottom: "30px" }}>
                                    <Label htmlFor="charset">{t("create_new_voucher.fields.charset")}</Label>
                                    <Field id="charset" name="charset">
                                        {({ field }: FieldProps) => (
                                            <TextInput
                                                {...field}
                                                type="text"
                                                error={errors.charset}
                                                $hasError={!!errors.charset && !!touched.charset}
                                                placeholder={t("create_new_voucher.fields.charset_placeholder")}
                                                onChange={(e) => {
                                                    const uniqueChars = Array.from(
                                                        new Set(e.target.value.toUpperCase().split(""))
                                                    ).join("");
                                                    field.onChange(e.target.name)(uniqueChars);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <Space $direction="horizontal" $padding={0}>
                                        {predefinedCharsets.map((elem) => (
                                            <Button
                                                key={elem.value}
                                                onClick={() => setFieldValue("charset", elem.value)}
                                                $variant="transparent"
                                                type="button"
                                                style={{
                                                    padding: 0,
                                                    color: theme.colors.info,
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                {elem.label}
                                            </Button>
                                        ))}
                                    </Space>
                                </Space>
                                <Space $padding={0}>
                                    <Label htmlFor="expires">{t("create_new_voucher.fields.expires")}</Label>
                                    <TextInput
                                        type="date"
                                        error={errors.expires}
                                        onChange={(e) =>
                                            setFieldValue(
                                                "expires",
                                                e.target.value ? new Date(e.target.value) : undefined
                                            )
                                        }
                                        $hasError={!!errors.expires && !!touched.expires}
                                    />
                                </Space>
                                <Space $padding={0}>
                                    <Label htmlFor="group_id">{t("create_new_voucher.fields.group_id")}</Label>
                                    <Field id="group_id" name="group_id">
                                        {({ field }: FieldProps) => (
                                            <TextInput
                                                {...field}
                                                type="text"
                                                error={errors.group_id}
                                                $hasError={!!errors.group_id && !!touched.group_id}
                                                placeholder={t("create_new_voucher.fields.group_id_placeholder")}
                                            />
                                        )}
                                    </Field>
                                </Space>
                                <InputGroupWrapper style={{ marginBottom: "30px" }}>
                                    <Space $fullWidth $padding={0}>
                                        <Label htmlFor="uses">{t("create_new_voucher.fields.uses")}</Label>
                                        <Field id="uses" name="uses">
                                            {({ field }: FieldProps) => (
                                                <TextInput
                                                    {...field}
                                                    type="number"
                                                    error={errors.uses}
                                                    $hasError={!!errors.uses && !!touched.uses}
                                                />
                                            )}
                                        </Field>
                                    </Space>
                                    <Space $fullWidth $padding={0}>
                                        <Label htmlFor="per_user">{t("create_new_voucher.fields.per_user")}</Label>
                                        <Field id="per_user" name="per_user">
                                            {({ field }: FieldProps) => (
                                                <TextInput
                                                    {...field}
                                                    type="number"
                                                    error={errors.per_user}
                                                    $hasError={!!errors.per_user && !!touched.per_user}
                                                />
                                            )}
                                        </Field>
                                    </Space>
                                </InputGroupWrapper>
                                <Space $padding={0}>
                                    <Label htmlFor="actions">{t("create_new_voucher.fields.actions")}</Label>
                                    <VoucherActionManager
                                        actions={values.actions}
                                        onChange={(actions) => setFieldValue("actions", actions)}
                                    />
                                </Space>
                                <Space $padding={0}>
                                    <Button
                                        onClick={() =>
                                            setFieldValue("actions", [
                                                ...values.actions,
                                                {
                                                    id: Date.now(),
                                                    targets: [],
                                                    add_hours: undefined,
                                                    new_cost: undefined,
                                                    coupon: undefined,
                                                    wallet_tx: undefined,
                                                },
                                            ])
                                        }
                                        type="button"
                                        $fullWidth
                                        $variant="info"
                                        disabled={isSubmitting}
                                    >
                                        {t("create_new_voucher.new_action")}
                                    </Button>
                                </Space>
                            </Space>
                        </Card>
                        <Card
                            style={{
                                position: "sticky",
                                top: 90,
                            }}
                            $maxWidth={600}
                            title={t("create_new_voucher.preview")}
                        >
                            <Space>
                                <Space $fullWidth $padding={0}>
                                    <Label htmlFor="preview">{t("create_new_voucher.preview_example")}</Label>
                                    <TextInput
                                        value={generateExampleVoucher(values)}
                                        disabled
                                        type="text"
                                        id="preview"
                                        $fullWidth
                                        readOnly
                                    />
                                </Space>
                                <Space $padding={0}>
                                    {Object.keys(errors).length > 0 && (
                                        <Paragraph $color={theme.colors.danger}>
                                            <ul>
                                                <Space $padding={0}>
                                                    {Object.keys(errors).length > 0 && (
                                                        <Paragraph $color={theme.colors.danger}>
                                                            <ul style={{ margin: 0, padding: 0 }}>
                                                                {Object.entries(errors).map(([key, error]) => (
                                                                    <li key={key}>{formatFormikError(error)}</li>
                                                                ))}
                                                            </ul>
                                                        </Paragraph>
                                                    )}
                                                </Space>
                                            </ul>
                                        </Paragraph>
                                    )}
                                </Space>
                                <Space $padding={0}>
                                    <CreateVoucherSummary {...values} />
                                </Space>
                                <Button
                                    $loadingText={t("common.submitting")}
                                    disabled={Object.keys(errors).length > 0}
                                    type="submit"
                                    $fullWidth
                                >
                                    {t("create_new_voucher.generate")}
                                </Button>
                            </Space>
                        </Card>
                    </FormWrapper>
                </Form>
            )}
        </Formik>
    );
};

export default CreateVoucherForm;
