import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { Button, Card, Label, Paragraph, Select, Space, TextInput } from "csrvui";
import { useTranslation } from "react-i18next";
import { Currencies, Money } from "ts-money";
import MoneyInput from "../../../../shared/components/MoneyInput";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { FormVoucherTarget } from "../../../Server/types";
import { FormVoucherAction } from "../../types";
import { InputGroupWrapper } from "../CreateVoucherForm/styles";
import VoucherActionWrapper from "../VoucherActionWrapper";
import VoucherTargetsEditor from "../VoucherTargetsEditor";

interface Props {
    actions: FormVoucherAction[];
    onChange: (actions: FormVoucherAction[]) => void;
}

const VoucherActionManager = ({ actions, onChange }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const updateAction = (index: number, key: keyof FormVoucherAction, value: unknown) => {
        const updatedActions = [...actions];
        updatedActions[index] = { ...updatedActions[index], [key]: value };
        onChange(updatedActions);
    };

    const removeAction = (index: number) => {
        const updatedActions = [...actions];
        updatedActions.splice(index, 1);
        onChange(updatedActions);
    };

    return (
        <Space $padding={0} $direction="vertical">
            {actions.map((action, index) => (
                <Card
                    key={action.id}
                    $titleComponent={
                        <Space style={{ borderBottom: `2px solid ${theme.colors.border}` }} $padding={0}>
                            <Space
                                $direction="horizontal"
                                $align="space-between"
                                $oppositeAlign="center"
                                $padding="11px 20px"
                            >
                                <Paragraph style={{ fontSize: "24px", fontWeight: 600 }}>Akcja {index + 1}</Paragraph>
                                <Button $variant="danger" onClick={() => removeAction(index)}>
                                    Usuń akcję
                                </Button>
                            </Space>
                        </Space>
                    }
                >
                    <Space $direction="vertical" $gap={20}>
                        <VoucherActionWrapper
                            title="Przedłuż serwer docelowy"
                            active={action.add_hours !== undefined}
                            onUpdate={() => {
                                updateAction(index, "add_hours", action.add_hours !== undefined ? undefined : "");
                            }}
                        >
                            <TextInput
                                $fullWidth
                                type="number"
                                placeholder="Dodaj godziny"
                                value={action.add_hours || ""}
                                onChange={(e) => updateAction(index, "add_hours", e.target.value)}
                                disabled={action.add_hours === undefined}
                            />
                        </VoucherActionWrapper>
                        <VoucherActionWrapper
                            active={!!action.coupon}
                            title="Modyfikuj produkt (kupon)"
                            onUpdate={() => {
                                updateAction(
                                    index,
                                    "coupon",
                                    action.coupon !== undefined
                                        ? undefined
                                        : {
                                              name: "",
                                              expression: "",
                                              add_hours_on_renewal: "",
                                              end_time: undefined,
                                              uses_left: 0,
                                          }
                                );
                            }}
                        >
                            <TextInput
                                $fullWidth
                                placeholder={t("create_new_voucher.fields.coupon_name")}
                                value={action.coupon?.name || ""}
                                type="text"
                                onChange={(e) =>
                                    updateAction(index, "coupon", { ...action.coupon, name: e.target.value })
                                }
                                disabled={action.coupon === undefined}
                            />

                            <InputGroupWrapper>
                                <Space $fullWidth $padding={0}>
                                    <Label style={{ fontWeight: "normal" }} htmlFor="add-hours-on-renewal">
                                        {" "}
                                        {t("create_new_voucher.fields.add_hours_on_renewal")}
                                    </Label>
                                    <TextInput
                                        placeholder={t("create_new_voucher.fields.add_hours_on_renewal")}
                                        value={action.coupon?.add_hours_on_renewal || ""}
                                        type="number"
                                        onChange={(e) =>
                                            updateAction(index, "coupon", {
                                                ...action.coupon,
                                                add_hours_on_renewal: e.target.value,
                                            })
                                        }
                                        disabled={action.coupon === undefined}
                                    />
                                </Space>
                                <Space $fullWidth $padding={0}>
                                    <Label style={{ fontWeight: "normal" }} htmlFor="add-hours-on-renewal">
                                        {t("create_new_voucher.fields.expires")}
                                    </Label>
                                    <TextInput
                                        $fullWidth
                                        placeholder={t("create_new_voucher.fields.expires")}
                                        type="date"
                                        onChange={(e) =>
                                            updateAction(index, "coupon", {
                                                ...action.coupon,
                                                end_time: e.target.value ? new Date(e.target.value) : undefined,
                                            })
                                        }
                                        disabled={action.coupon === undefined}
                                    />
                                </Space>
                            </InputGroupWrapper>
                            <InputGroupWrapper>
                                <Space $fullWidth $padding={0}>
                                    <Label style={{ fontWeight: "normal" }} htmlFor="uses-left">
                                        {t("create_new_voucher.fields.uses_left")}
                                    </Label>
                                    <TextInput
                                        $fullWidth
                                        placeholder={t("create_new_voucher.fields.uses_left")}
                                        type="number"
                                        value={action.coupon?.uses_left || 0}
                                        onChange={(e) =>
                                            updateAction(index, "coupon", {
                                                ...action.coupon,
                                                uses_left: Number(e.target.value),
                                            })
                                        }
                                        disabled={action.coupon === undefined}
                                    />
                                </Space>
                                <Space $fullWidth $padding={0}>
                                    <Label style={{ fontWeight: "normal" }} htmlFor="expression">
                                        {t("create_new_voucher.fields.expression")}
                                    </Label>
                                    <TextInput
                                        $fullWidth
                                        placeholder={t("create_new_voucher.fields.expression")}
                                        value={action.coupon?.expression || ""}
                                        type="text"
                                        onChange={(e) =>
                                            updateAction(index, "coupon", {
                                                ...action.coupon,
                                                expression: e.target.value,
                                            })
                                        }
                                        disabled={action.coupon === undefined}
                                    />
                                </Space>
                            </InputGroupWrapper>
                        </VoucherActionWrapper>

                        <VoucherActionWrapper
                            title={t("create_new_voucher.fields.wallet_tx")}
                            active={!!action.wallet_tx}
                            onUpdate={() => {
                                updateAction(
                                    index,
                                    "wallet_tx",
                                    action.wallet_tx !== undefined ? undefined : new Money(0, Currencies.PLN.code)
                                );
                                if (action.wallet_tx === undefined) {
                                    updateAction(index, "wallet_tx", [
                                        {
                                            currency: Currencies.PLN.code,
                                            amount: "0",
                                        },
                                    ]);
                                }
                            }}
                        >
                            <Space style={{ marginTop: "10px" }} $padding={0} $fullWidth>
                                {action.wallet_tx &&
                                    action.wallet_tx.length > 0 &&
                                    action.wallet_tx.map((wallet, walletIndex) => (
                                        <Space
                                            $padding={0}
                                            $align="center"
                                            $fullWidth
                                            $oppositeAlign="center"
                                            $wrap={false}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={walletIndex}
                                            $direction="horizontal"
                                        >
                                            <Select
                                                $fullWidth
                                                $maxWidth={120}
                                                options={[
                                                    { value: Currencies.PLN.code, name: Currencies.PLN.code },
                                                    { value: Currencies.USD.code, name: Currencies.USD.code },
                                                ]}
                                                value={wallet.currency}
                                                defaultValue={wallet.currency}
                                                onChange={(value) => {
                                                    const updatedWalletTx = [...action.wallet_tx];
                                                    updatedWalletTx[walletIndex] = new Money(
                                                        Number(updatedWalletTx[walletIndex].amount || 0),
                                                        value
                                                    );

                                                    updateAction(index, "wallet_tx", updatedWalletTx);
                                                }}
                                            />
                                            <MoneyInput
                                                $fullWidth
                                                placeholder="Wprowadź kwotę"
                                                value={new Money(
                                                    Number(wallet.amount) ?? 0,
                                                    wallet.currency
                                                ).toDecimal()}
                                                onChange={(val) => {
                                                    const updatedWalletTx = [...action.wallet_tx];

                                                    updatedWalletTx[walletIndex] = Money.fromDecimal(
                                                        Number(val),
                                                        updatedWalletTx[walletIndex].currency
                                                    );

                                                    updateAction(index, "wallet_tx", updatedWalletTx);
                                                }}
                                            />
                                            <Button
                                                onClick={() => {
                                                    const updatedWalletTx = [...action.wallet_tx];
                                                    updatedWalletTx.splice(walletIndex, 1);
                                                    updateAction(index, "wallet_tx", updatedWalletTx);
                                                }}
                                                disabled={action.wallet_tx.length === 1}
                                                $sizeVariant="small"
                                                $variant="danger"
                                            >
                                                <MdDelete />
                                            </Button>
                                        </Space>
                                    ))}
                                {action.wallet_tx && (
                                    <Button
                                        $variant="transparent"
                                        type="button"
                                        style={{
                                            color: theme.colors.info,
                                            textDecoration: "underline",
                                            padding: 0,
                                            marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                            updateAction(index, "wallet_tx", [
                                                ...action.wallet_tx,
                                                new Money(0, Currencies.PLN.code),
                                            ]);
                                        }}
                                    >
                                        + Nowa opcja
                                    </Button>
                                )}
                            </Space>
                        </VoucherActionWrapper>
                        <VoucherActionWrapper
                            title={t("create_new_voucher.fields.targets")}
                            active={!!action.targets && action.targets?.length > 0}
                            onUpdate={() => {
                                const target: FormVoucherTarget = {
                                    billing_period: "",
                                    cluster_id: "",
                                    product_id: "*",
                                    user_parameters: {},
                                    id: Date.now().toString(),
                                };

                                updateAction(index, "targets", action.targets?.length ? [] : [target]);
                            }}
                        >
                            {action.targets &&
                                action.targets.map((target, targetIndex) => (
                                    <Space $fullWidth $padding={0} key={target.id}>
                                        <VoucherTargetsEditor
                                            values={target}
                                            onUpdate={(target) => {
                                                const updatedTargets = action.targets ? [...action.targets] : [];
                                                updatedTargets[targetIndex] = target;

                                                updateAction(index, "targets", updatedTargets);
                                            }}
                                        />
                                        <Space
                                            $direction="horizontal"
                                            $align="end"
                                            $oppositeAlign="center"
                                            $fullWidth
                                            $padding={0}
                                        >
                                            {action.targets && action.targets.length > 1 && (
                                                <Button
                                                    onClick={() => {
                                                        const updatedTargets = action.targets
                                                            ? [...action.targets]
                                                            : [];
                                                        const data = updatedTargets.filter(
                                                            (updatedTarget) => updatedTarget.id !== target.id
                                                        );

                                                        updateAction(index, "targets", data);
                                                    }}
                                                    disabled={action.targets?.length === 1}
                                                    style={{
                                                        color: theme.colors.danger,
                                                        textDecoration: "underline",
                                                        padding: 0,
                                                    }}
                                                    $variant="transparent"
                                                >
                                                    {t("common.delete")}
                                                </Button>
                                            )}
                                            {action.targets && targetIndex === action.targets.length - 1 && (
                                                <Button
                                                    $variant="transparent"
                                                    type="button"
                                                    style={{
                                                        color: theme.colors.info,
                                                        textDecoration: "underline",
                                                        padding: 0,
                                                    }}
                                                    onClick={() => {
                                                        const target: FormVoucherTarget = {
                                                            billing_period: "",
                                                            cluster_id: "",
                                                            product_id: "*",
                                                            user_parameters: {},
                                                            id: Date.now().toString(),
                                                        };

                                                        updateAction(index, "targets", [
                                                            ...(action.targets || []),
                                                            target,
                                                        ]);
                                                    }}
                                                >
                                                    {t("create_new_voucher.new_target")}
                                                </Button>
                                            )}
                                        </Space>
                                    </Space>
                                ))}
                        </VoucherActionWrapper>
                    </Space>
                </Card>
            ))}
        </Space>
    );
};

export default VoucherActionManager;
