import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import CustomError from "../CustomError";
import { ApiError } from "../types";

export const transformError = (baseQueryReturnValue: FetchBaseQueryError) => {
    const error = baseQueryReturnValue as ApiError;

    if (error.data) {
        const customError = new CustomError(error.data.error, error.data.event_id);
        customError.error = error.data.error;

        if (error.data.fields?.resource) {
            customError.fields = { resource: error.data.fields.resource };
        }

        return customError;
    }
    return error;
};
