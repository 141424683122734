import { FaPlay } from "@react-icons/all-files/fa/FaPlay";
import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown";
import { Button, Paragraph, Space } from "csrvui";
import { ButtonProps } from "csrvui/dist/esm/components/Button";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CurrentServerContext } from "../../../../shared/components/CurrentServerProvider";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { BillingProduct } from "../../../Billings/types";
import useCreateServer from "../../../Server/hooks/useCreateServer";
import useReleases from "../../../Server/modules/Apps/hooks/useReleases";
import { useServersQuery } from "../../../User/api";
import useUserData from "../../../User/hooks/useUserData";
import useUserVouchers from "../../../User/hooks/useUserVouchers";
import useVerify from "../../../User/hooks/useVerify";
import { InstallprojectModalOptions, ProjectRelease } from "../../types";
import { PackageLabels } from "../Marketplace/types";
import RedeemFreeServerModal from "../RedeemFreeServerModal";

interface Props extends Omit<ButtonProps, "$variant"> {
    release?: ProjectRelease;
    onShowInstaller: (options?: InstallprojectModalOptions) => void;
    onServerIsRunning?: () => void;
    onSuccess?: () => void;
    $variant?: "default" | "playnow";
    product?: BillingProduct | null;
}

const InstallButton = ({
    release,
    onShowInstaller,
    onServerIsRunning,
    onSuccess,
    $variant,
    product = null,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const { server } = useContext(CurrentServerContext);
    const [modalSettings, setModalSettings] = useState<{
        isOpen: boolean;
        product: BillingProduct | null;
    }>({
        isOpen: false,
        product,
    });
    const { installRelease } = useReleases();
    const { projectid } = useParams();
    const { isLoggedIn } = useVerify();
    const theme = useTheme();
    const navigate = useNavigate();
    const { userAlreadyTestedServer } = useUserVouchers();
    const { redeemFreeServer } = useCreateServer();
    const { user } = useUserData();
    const { data: servers } = useServersQuery(user && user.id, {
        skip: !user,
    });
    const activeServers = servers ? servers.filter((server) => server.active) : [];
    const canUserFreeServer = useMemo(() => {
        if (!product) return true;
        return !userAlreadyTestedServer(product?.id);
    }, [product, userAlreadyTestedServer]);

    const quickInstall = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();

            if (props.$loading || !release) return;

            const hashToInstall = release.artifacts.find((el) => el.type === "kloceg")?.hash;

            if (user && activeServers.length === 0 && canUserFreeServer && product && hashToInstall) {
                redeemFreeServer(product, true, {
                    engine: hashToInstall,
                });

                return;
            }

            if (isLoggedIn && server.id) {
                if (release.artifacts.find((el) => el.details.labels[PackageLabels.TYPE] === "PACKAGE")) {
                    onShowInstaller({ hideInstallOptions: true });
                } else {
                    installRelease(
                        `${projectid}:${hashToInstall}`,
                        server.id,
                        () => onServerIsRunning && onServerIsRunning(),
                        () => onSuccess && onSuccess(),
                        {
                            force: true,
                            install_deps: true,
                        }
                    );
                }
            } else if (isLoggedIn) {
                onShowInstaller({
                    hideInstallOptions: true,
                });
            } else if (canUserFreeServer) {
                setModalSettings({ isOpen: true, product });
            } else {
                navigate("/offers/create?products=AMETHYST");
            }
        },
        [
            props.$loading,
            release,
            user,
            activeServers.length,
            canUserFreeServer,
            product,
            isLoggedIn,
            server.id,
            redeemFreeServer,
            onShowInstaller,
            installRelease,
            projectid,
            onServerIsRunning,
            onSuccess,
            navigate,
        ]
    );

    const handleShowInstaller = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            onShowInstaller({
                hideInstallOptions: true,
            });
        },
        [onShowInstaller]
    );

    if ($variant === "playnow") {
        const hashToInstall = release ? release.artifacts.find((el) => el.type === "kloceg")?.hash : "";

        return (
            <>
                <Button
                    {...props}
                    data-ph-capture-attribute-id="install_button:play_now"
                    data-ph-capture-attribute-project-id={projectid}
                    onClick={(e) => {
                        quickInstall(e);
                    }}
                    disabled={!props.$loading && (!release || props.disabled)}
                    $variant="success"
                    style={{
                        padding: "10px 10px",
                        boxShadow: ` 0px 2px 10px ${theme.colors.success}`,
                        height: "60px",
                        width: "100%",
                        marginTop: "10px",
                        fontSize: "20px",
                        ...props.style,
                    }}
                >
                    <Space $direction="horizontal" $align="center" $oppositeAlign="center" $padding={0} $gap={15}>
                        <FaPlay size={25} />
                        <Paragraph
                            $level={3}
                            style={{
                                fontWeight: 550,
                                color: theme.colors.secondaryText,
                                textTransform: "uppercase",
                            }}
                        >
                            {release ? t("marketplace.run") : t("marketplace.no_releases")}
                        </Paragraph>
                    </Space>
                </Button>
                <RedeemFreeServerModal
                    preinstallProject={`${projectid}:${hashToInstall}`}
                    open={modalSettings.isOpen}
                    product={modalSettings.product}
                    onClose={() => setModalSettings({ isOpen: false, product: null })}
                />
            </>
        );
    }

    return (
        <>
            <Space $fullWidth={props.$fullWidth} $wrap={false} $padding={0} $direction="horizontal" $gap={0}>
                <Button
                    {...props}
                    data-ph-capture-attribute-id="install_button:install"
                    data-ph-capture-attribute-project-id={projectid}
                    style={{
                        borderRadius: "8px 0 0 8px",
                        padding: "13px 10px 13px 14px",
                        border: "none",
                        height: "46px",
                        ...props.style,
                    }}
                    onClick={(e) => {
                        quickInstall(e);
                    }}
                    $disabledText={t("marketplace.no_releases")}
                    $loadingText={t("marketplace.install_newest_release")}
                    disabled={!release || props.disabled}
                    $variant="secondary"
                >
                    {props.children}
                </Button>

                <Button
                    {...props}
                    style={{
                        borderRadius: "0 8px 8px 0",
                        padding: "10px 2px 10px 0px",
                        maxWidth: "80px",
                        border: "none",
                        ...props.style,
                    }}
                    data-ph-capture-attribute-id="install_button:installer"
                    data-ph-capture-attribute-project-id={projectid}
                    onClick={handleShowInstaller}
                    $loading={false}
                    $loadingText={undefined}
                    $disabledText={undefined}
                    disabled={!release || props.disabled}
                    $variant="secondary"
                >
                    <Space $padding={0} style={{ marginTop: "-4px" }}>
                        <MdArrowDropDown size={30} />
                    </Space>
                </Button>
            </Space>
            <RedeemFreeServerModal
                preinstallProject={release && release.project_id}
                open={modalSettings.isOpen}
                product={modalSettings.product}
                onClose={() => setModalSettings({ isOpen: false, product: null })}
            />
        </>
    );
};

export default InstallButton;
