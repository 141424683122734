import CsrvFS from "csrvfs";
import CsrvWebFTP from "csrvfs/dist/CsrvRemoteFS";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    useGetProductsQuery,
    useLazyGetServiceByIdQuery,
    useLazyGetServiceCostQuery,
} from "../../../modules/Billings/api";
import { BillingService } from "../../../modules/Billings/types";
import {
    useGenerateRemotefsTokenMutation,
    useLazyServerByIdQuery,
    useLazyServerStatusQuery,
} from "../../../modules/Server/api";
import useServerPackages from "../../../modules/Server/hooks/useServerPackages";
import { useLazyWalletByIdQuery } from "../../../modules/Server/modules/Wallets/api";
import {
    clearSlice,
    InstalledAppsState,
    InstalledPackagesState,
    LoadingState,
    ServiceCostState,
    setCurrentServer,
    setCurrentService,
    setCurrentServiceCost,
    setCurrentWallet,
    WalletState,
} from "../../../modules/Server/slices/currentServerSlice";
import {
    getCurrentServer,
    getCurrentService,
    getCurrentServiceCost,
    getCurrentWallet,
    getInstalledApps,
    getInstalledPackages,
    getServerStatus,
} from "../../../modules/Server/slices/selectors";
import {
    clearServerStatus,
    ServerStatusState,
    setServerStatus,
} from "../../../modules/Server/slices/serverStatusSlice";
import { ServerWithParameters } from "../../../modules/Server/types";
import getRawPriceForProduct from "../../../modules/User/helpers/getRawPriceForProduct";
import { HostUrl } from "../../../rootTypes";
import CustomError from "../../CustomError";
import { showApiError } from "../../helpers/showToast";
import usePingServer from "../../helpers/usePingServer";
import { ProcessesContext } from "../../hooks/ProcessesContext";
import useCurrentServerIdentitites from "../../hooks/useCurrentServerIdentitites";
import GlobalTransitionLoader from "../GlobalTransitionLoader";

interface Props {
    children: JSX.Element;
    disableTransition?: boolean;
    overwritedServerId?: string;
}

interface CurrentServerContextInterface {
    // Data
    server: ServerWithParameters;
    service: BillingService;
    serviceCost: ServiceCostState;
    primaryEngine: string | null | undefined;
    status: ServerStatusState;
    canBeUpgraded: boolean | undefined;
    wallet: WalletState;
    csrvfs: CsrvFS | undefined | null;
    webFTP: CsrvWebFTP | undefined | null;
    installedPackages: InstalledPackagesState;
    installedApps: InstalledAppsState;
    // Functions
    refreshCurrentServerData: () => Promise<void>;
    refreshInstalledPackages: () => Promise<void>;
    refreshWallet: () => Promise<void>;
    clearServerData: () => void;
}

const initLoadingState: LoadingState = {
    loading: true,
    error: null,
    data: undefined,
};

export const CurrentServerContext = createContext<CurrentServerContextInterface>({
    // Data
    server: {} as ServerWithParameters,
    service: {} as BillingService,
    serviceCost: initLoadingState,
    installedPackages: initLoadingState,
    installedApps: initLoadingState,
    wallet: initLoadingState,
    status: initLoadingState,
    primaryEngine: "",
    canBeUpgraded: undefined,
    csrvfs: undefined,
    webFTP: undefined,
    // Functions
    refreshCurrentServerData: async () => {},
    refreshInstalledPackages: async () => {},
    refreshWallet: async () => {},
    clearServerData: () => {},
});

// const url = window.location.origin.includes("localhost") ? HostUrl : window.location.origin;

const CurrentServerProvider: React.FC<Props> = ({ children, disableTransition, overwritedServerId }) => {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [generateRemotefsToken] = useGenerateRemotefsTokenMutation();
    const { resubscribe } = useContext(ProcessesContext);

    // data
    const { serverId, checked } = useCurrentServerIdentitites();
    const currentServerId = useMemo(() => overwritedServerId || serverId, [overwritedServerId, serverId]);
    // const { user, updateUserData, isLoading: isUserLoading } = useUserData();
    const { data: products } = useGetProductsQuery();
    const [fetchServerById] = useLazyServerByIdQuery();
    const [fetchServiceById] = useLazyGetServiceByIdQuery();
    const [fetchServiceCost] = useLazyGetServiceCostQuery();
    const [fetchWalletById] = useLazyWalletByIdQuery();
    const [fetchServerStatusById] = useLazyServerStatusQuery();
    /**
     * Fetches the current server data by its ID and updates the state with the fetched data.
     *
     * @param {string} id - The ID of the server to fetch.
     * @returns {Promise<void>} A promise that resolves when the server data has been fetched and the state has been updated.
     *
     * @async
     * @function
     * @name getCurrentServerData
     */
    const getCurrentServerData = useCallback(
        async (id: string) => {
            try {
                const data = await fetchServerById(id).unwrap();
                dispatch(
                    setCurrentServer({
                        data,
                    })
                );
            } catch (error) {
                const err = error as CustomError;
                dispatch(
                    setCurrentServer({
                        error: err,
                    })
                );
            }
        },
        [dispatch, fetchServerById]
    );

    /**
     * Fetches the service data for the given service ID and dispatches an action to set the current service.
     *
     * @param {string} id - The ID of the service to fetch.
     * @returns {Promise<BillingService | null>} A promise that resolves when the service data has been fetched and the action has been dispatched.
     */
    const getCurrentServiceData = useCallback(
        async (id: string) => {
            try {
                const data = await fetchServiceById(id).unwrap();
                dispatch(
                    setCurrentService({
                        data,
                    })
                );

                return data;
            } catch (error) {
                const err = error as CustomError;
                dispatch(
                    setCurrentService({
                        error: err,
                    })
                );
                return null;
            }
        },
        [dispatch, fetchServiceById]
    );

    const getCurrentServerStatus = useCallback(
        async (id: string) => {
            try {
                dispatch(
                    setServerStatus({
                        loading: true,
                    })
                );
                const data = await fetchServerStatusById(id).unwrap();
                dispatch(
                    setServerStatus({
                        data,
                    })
                );
            } catch (error) {
                const err = error as CustomError;
                dispatch(
                    setServerStatus({
                        error: err,
                    })
                );
            }
        },
        [dispatch, fetchServerStatusById]
    );

    /**
     * Fetches the current service cost for a given wallet ID and updates the state with the fetched cost.
     *
     * @param {string} walletId - The ID of the wallet for which to fetch the service cost.
     * @returns {Promise<void>} A promise that resolves when the service cost has been fetched and the state has been updated.
     */
    const getCurrentServiceCostData = useCallback(
        async (serviceId: string) => {
            try {
                dispatch(
                    setCurrentServiceCost({
                        loading: true,
                    })
                );

                const { cost } = await fetchServiceCost({
                    id: serviceId,
                }).unwrap();
                dispatch(
                    setCurrentServiceCost({
                        data: cost,
                    })
                );
            } catch (error) {
                const err = error as CustomError;
                dispatch(
                    setCurrentServiceCost({
                        error: err,
                    })
                );
            }
        },
        [dispatch, fetchServiceCost]
    );

    /**
     * Fetches the wallet data by the given ID and dispatches an action to set the current wallet.
     *
     * @param {string} id - The ID of the wallet to fetch.
     * @returns {Promise<void>} A promise that resolves when the wallet data has been fetched and the action has been dispatched.
     */
    const getCurrentWalletById = useCallback(
        async (id: string) => {
            try {
                dispatch(
                    setCurrentWallet({
                        loading: true,
                    })
                );
                const data = await fetchWalletById(id).unwrap();
                dispatch(
                    setCurrentWallet({
                        data,
                    })
                );
            } catch (error) {
                const err = error as CustomError;
                dispatch(
                    setCurrentWallet({
                        error: err,
                    })
                );
            }
        },
        [dispatch, fetchWalletById]
    );

    // selectors
    const currentServer = useAppSelector(getCurrentServer);
    const service = useAppSelector(getCurrentService);
    const serviceCost = useAppSelector(getCurrentServiceCost);
    const wallet = useAppSelector(getCurrentWallet);
    const serverStatus = useAppSelector(getServerStatus);
    const installedPackages = useAppSelector(getInstalledPackages);
    const installedApps = useAppSelector(getInstalledApps);

    useEffect(() => {
        if (checked && currentServerId) {
            getCurrentServiceData(currentServerId).then((s) => {
                if (s) {
                    getCurrentWalletById(s.wallet_id);
                }
            });
        }
    }, [checked, currentServerId, getCurrentServiceData, getCurrentWalletById]);

    useEffect(() => {
        if (checked && currentServerId) {
            const promises = [getCurrentServerData(currentServerId)];

            Promise.all(promises).then(() => {
                getCurrentServerStatus(currentServerId);
                getCurrentServiceCostData(currentServerId);
            });
        }
    }, [checked, currentServerId, getCurrentServerData, getCurrentServerStatus, getCurrentServiceCostData]);

    const primaryEngine = useMemo(() => {
        return currentServer.data?.parameters.game?.engine || null;
    }, [currentServer]);

    usePingServer(currentServerId);

    // Generates url to remotefs based on last_node and server id
    const remotefsUrl = useMemo(() => {
        if (!currentServer?.data?.last_node || !serverStatus?.data?.pod_name) {
            return null;
        }

        return `https://${currentServer.data.last_node}/${currentServer.data.id}/remotefs`;
    }, [currentServer, serverStatus]);

    // Check if we have user data and server parameter in URL is verified and we know what is the server id

    const canBeUpgraded = useMemo(() => {
        const serviceData = service.data;

        if (!serviceData || !products) return false;

        const currentProduct = products.find((product) => product.id === serviceData.product);

        if (!currentProduct) return false;

        return products.some(
            (product) => getRawPriceForProduct(product, "PLN") > getRawPriceForProduct(currentProduct, "PLN")
        );
    }, [service, products]);

    const csrvfsInstance = useRef<CsrvFS | null>(null as CsrvFS | null);
    const [webFTPInstance, setWebFTPInstance] = useState<CsrvWebFTP | null>(null);

    useEffect(() => {
        if (currentServerId) {
            resubscribe(currentServerId);
        }
    }, [currentServerId, resubscribe]);

    useEffect(() => {
        return () => {
            resubscribe(undefined);
        };
        // umyślnie, chcemy tylko raz zrobić callback na unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initCSRVFS = useCallback(
        async (serviceId: string, url: string) => {
            try {
                const tokenResponse = await generateRemotefsToken(serviceId).unwrap();
                csrvfsInstance.current = new CsrvFS(HostUrl, url, serviceId, {
                    token: tokenResponse.token,
                });
                const webFTPInstance = new CsrvWebFTP(HostUrl, url, serviceId, {
                    token: tokenResponse.token,
                });

                setWebFTPInstance(webFTPInstance);
            } catch (e) {
                showApiError(e);
            }
        },
        [generateRemotefsToken]
    );

    // Initialize csrvfs
    useEffect(() => {
        if (currentServerId && remotefsUrl) {
            initCSRVFS(currentServerId, remotefsUrl);
        }
    }, [currentServerId, remotefsUrl, initCSRVFS]);

    const { refresh: refreshAppsAndPackages } = useServerPackages(webFTPInstance || null, currentServer.data || null);

    const memoizedValues = useMemo(
        () => ({
            primaryEngine: primaryEngine as string,
            csrvfs: csrvfsInstance.current,
            webFTP: webFTPInstance,
            canBeUpgraded,
        }),
        [primaryEngine, webFTPInstance, canBeUpgraded]
    );

    const refreshCurrentServerData = useCallback(async () => {
        if (currentServerId) {
            const promises = [
                getCurrentServerData(currentServerId),
                getCurrentServiceData(currentServerId).then((s) => {
                    if (s) {
                        getCurrentWalletById(s.wallet_id);
                    }
                }),
                getCurrentServerStatus(currentServerId),
                getCurrentServiceCostData(currentServerId),
            ];

            Promise.all(promises);
        }
    }, [
        currentServerId,
        getCurrentServerData,
        getCurrentServiceData,
        getCurrentServerStatus,
        getCurrentServiceCostData,
        getCurrentWalletById,
    ]);

    const refreshInstalledPackages = useCallback(async () => {
        await refreshAppsAndPackages();
    }, [refreshAppsAndPackages]);

    const refreshWallet = useCallback(async () => {
        if (!service.loading && !service.error) {
            await getCurrentWalletById(service.data.wallet_id);
        }
    }, [getCurrentWalletById, service]);

    const clearServerData = useCallback(() => {
        dispatch(clearSlice());
        dispatch(clearServerStatus());
    }, [dispatch]);

    const providerValue: CurrentServerContextInterface = useMemo(
        () => ({
            // type casting because we know that if we render children of provider, data are fetched
            server: currentServer.data as ServerWithParameters,
            service: service.data as BillingService,
            wallet,
            serviceCost,
            installedPackages,
            installedApps,
            primaryEngine: memoizedValues.primaryEngine,
            status: serverStatus,
            canBeUpgraded: memoizedValues.canBeUpgraded,
            refreshCurrentServerData,
            refreshInstalledPackages,
            refreshWallet,
            clearServerData,
            csrvfs: memoizedValues.csrvfs,
            webFTP: memoizedValues.webFTP,
        }),
        [
            currentServer.data,
            service.data,
            wallet,
            serviceCost,
            installedPackages,
            installedApps,
            serverStatus,
            refreshCurrentServerData,
            refreshInstalledPackages,
            refreshWallet,
            clearServerData,
            memoizedValues,
        ]
    );

    useEffect(() => {
        return () => {
            dispatch(clearSlice());
            dispatch(clearServerStatus());
        };
    }, [dispatch]);

    // Waits for user data to load and checking URL server param
    if (!checked) {
        return <GlobalTransitionLoader text={t("loading.data")} inProp />;
    }

    if (!currentServerId) {
        return <Navigate to="/404" />;
    }

    if (!currentServer.loading && currentServer.error) {
        showApiError(currentServer.error);
        return <Navigate to="/account" />;
    }

    if (!service.loading && service.error) {
        showApiError(service.error);
        return <Navigate to="/account" />;
    }

    // if (user)

    // if (!isLoading && !isFetching && checked && !serverData && !isUserLoading && serverId !== undefined) {
    //     if (isError && hasUserCapability(user, UserPermissions.SERVER)) {
    //         if (user.services.length > 0) {
    //             navigate("/unauthorized-server", {
    //                 replace: true,
    //                 state: {
    //                     from: window.location.pathname.replace(serverId as string, ":serverid"),
    //                 },
    //             });
    //         } else {
    //             navigate("/account");
    //         }
    //         return null;
    //     }
    //     if (!serverData) {
    //         showApiError(serverError);
    //         return <Navigate to="/account" />;
    //     }
    // }

    if (disableTransition) {
        return <CurrentServerContext.Provider value={providerValue}>{children}</CurrentServerContext.Provider>;
    }

    /* 
        Now we are sure that children will be rendered only when we have data about current server and service
    */
    const serverOrServiceNotReady = currentServer.loading || service.loading;

    return (
        <CurrentServerContext.Provider value={providerValue}>
            <GlobalTransitionLoader text={t("loading.data")} inProp={serverOrServiceNotReady} />
            {/* Renders children only if server and service data is ready */}
            {!serverOrServiceNotReady && children}
        </CurrentServerContext.Provider>
    );
};

export default CurrentServerProvider;
