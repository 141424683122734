import { Space, Title } from "csrvui";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "react-transition-group";
import { CurrentServerContext } from "../CurrentServerProvider";
import { StyledLoader, StyledLoaderContainer } from "./style";
import { Props } from "./types";

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    height: "100vh",
    top: 0,
};

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
};

const PanelLoader: React.FC<Props> = ({ text, inProp, hideLoader }) => {
    const { t } = useTranslation();
    const { service } = useContext(CurrentServerContext);
    const nodeRef = useRef(null);

    if (!service) {
        return (
            <Transition nodeRef={nodeRef} in={inProp} timeout={duration} unmountOnExit>
                {(state) => (
                    <StyledLoaderContainer
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    >
                        <Space $padding={0} $gap={25}>
                            {!hideLoader && <StyledLoader />}
                            <Title $level={2}>{text || t("loading.default")}</Title>
                        </Space>
                    </StyledLoaderContainer>
                )}
            </Transition>
        );
    }

    return (
        <Transition nodeRef={nodeRef} in={inProp} timeout={duration} unmountOnExit>
            {(state) => (
                <StyledLoaderContainer
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state],
                    }}
                >
                    <Space $padding={0} $gap={25}>
                        {!hideLoader && <StyledLoader />}
                        <Title $level={2}>{text || t("loading.default")}</Title>
                    </Space>
                </StyledLoaderContainer>
            )}
        </Transition>
    );
};

export default PanelLoader;
