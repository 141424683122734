import { Space } from "csrvui";
import { useMemo } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import useTheme from "../../hooks/useCustomTheme";
import { LandingCarouselItem } from "../../types";
import LandingCarouselCard from "../LandingCarouselItem";

interface Props {
    items: LandingCarouselItem[];
}

const handleDragStart = (e: React.DragEvent<HTMLDivElement>): void => e.preventDefault();

const LandingCarousel = ({ items }: Props) => {
    const theme = useTheme();

    const carouselData = useMemo(() => {
        return items.map((item) => <LandingCarouselCard onDragStart={handleDragStart} key={item.name} data={item} />);
    }, [items]);

    return (
        <Space
            $padding={0}
            style={{
                maxWidth: "calc(100vw - 11px)",
                overflow: "hidden",
            }}
        >
            <Space
                style={{
                    backgroundColor: theme.colors.primaryBackground,
                    overflow: "hidden",
                    maxWidth: "100vw",
                }}
                $padding="20px 0"
            >
                <AliceCarousel
                    autoHeight
                    animationType="fadeout"
                    disableButtonsControls
                    controlsStrategy="responsive"
                    autoPlay
                    autoPlayInterval={2000}
                    infinite
                    autoWidth
                    disableDotsControls
                    mouseTracking
                    items={carouselData}
                />
            </Space>
        </Space>
    );
};

export default LandingCarousel;
