import { Paragraph, Space } from "csrvui";
import { Toggle } from "../../../../shared/components/CookiesBanner/style";

interface Props {
    active: boolean;
    onUpdate: () => void;
    children: React.ReactNode;
    title: string;
}

const VoucherActionWrapper = ({ active, onUpdate, children, title }: Props) => {
    return (
        <Space $gap={2} $padding={0}>
            <Space $padding={0} $direction="horizontal" $align="space-between" $oppositeAlign="center">
                <Paragraph
                    style={{
                        fontWeight: 600,
                        textTransform: "uppercase",
                    }}
                >
                    {title}
                </Paragraph>
                <Toggle active={active} type="button" onClick={onUpdate} />
            </Space>
            {active && (
                <Space $padding={0} $align="center" $oppositeAlign="center" $wrap={false}>
                    {children}
                </Space>
            )}
        </Space>
    );
};

export default VoucherActionWrapper;
