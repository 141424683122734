import styled from "styled-components";

export const InputGroupWrapper = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    gap: 20px;

    @media (max-width: 1100px) {
        flex-wrap: wrap;
    }
`;
