import { CsrvProcessInstance, CsrvProcessType } from "@craftserve/csrvprocess";
import { createContext } from "react";

interface ProcessesContextType {
    isConnected: boolean;
    processes: CsrvProcessInstance[];
    messages: CsrvProcessInstance[];
    addMessage: (message: CsrvProcessInstance) => void;
    addSimpleMessage: (type: CsrvProcessType, success: boolean) => void;
    removeMessage: (messageId: string) => void;
    deleteProcess: (processId: string) => void;
    unsubscribe: () => void;
    resubscribe: (id: string | undefined) => void;
}

export const ProcessesContext = createContext<ProcessesContextType>({
    isConnected: false,
    processes: [],
    messages: [],
    addMessage: () => {},
    addSimpleMessage: () => {},
    removeMessage: () => {},
    deleteProcess: () => {},
    unsubscribe: () => {},
    resubscribe: () => {},
});
