import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
    Masonry,
    MasonryCellProps,
    WindowScroller,
} from "react-virtualized";
import AutoSizer from "react-virtualized-auto-sizer";
import { MarketProject } from "../../types";
import MarketplaceCard from "../Marketplace/MarketplaceCard";
import MarketplaceSkeletonCard from "../MarketplaceSkeletonCard";

interface Props {
    data?: MarketProject[];
    marketplaceUrl: string;
    isLoading?: boolean;
    scrollElement?: HTMLElement;
}

const gutterSize = 10;

const VirtualizedMarketplace = ({ data, marketplaceUrl, isLoading, scrollElement }: Props) => {
    const masonryRef = useRef<Masonry>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [columnWidth, setColumnWidth] = useState(460);
    const [columnCount, setColumnCount] = useState(4);
    const prevWidthRef = useRef<number | null>(null);

    const cellCount = data ? data.length : 10;

    const cache = useMemo(
        () =>
            new CellMeasurerCache({
                defaultWidth: columnWidth,
                defaultHeight: 300,
                fixedWidth: true,
                fixedHeight: true,
            }),
        [columnWidth]
    );

    const calculateColumnCount = useCallback((width: number) => {
        const newColumnCount = Math.max(1, Math.floor(width / 500));
        setColumnCount(newColumnCount);
        setColumnWidth(Math.floor((width - (newColumnCount - 1) * gutterSize) / newColumnCount));
    }, []);

    const onResize = useCallback(
        ({ width }: { width: number }) => {
            if (prevWidthRef.current !== width) {
                calculateColumnCount(width);
                cache.clearAll();
                if (masonryRef.current) {
                    masonryRef.current.clearCellPositions();
                    masonryRef.current.recomputeCellPositions();
                }
                prevWidthRef.current = width;
            }
        },
        [calculateColumnCount, cache]
    );

    const cellPositioner = useMemo(() => {
        return createMasonryCellPositioner({
            cellMeasurerCache: cache,
            columnCount,
            columnWidth,
            spacer: gutterSize,
        });
    }, [cache, columnCount, columnWidth]);

    const cellRenderer = ({ index, key, parent, style }: MasonryCellProps) => {
        if (isLoading) {
            return (
                <CellMeasurer cache={cache} index={index} key={key} parent={parent} rowIndex={index}>
                    <div style={style} key={key}>
                        <MarketplaceSkeletonCard key={key} />
                    </div>
                </CellMeasurer>
            );
        }

        if (!data || index >= data.length) {
            return null;
        }

        const project = data[index];

        if (!project) {
            return (
                <CellMeasurer cache={cache} index={index} key={key} parent={parent} rowIndex={index}>
                    <div style={style} key={key}>
                        <MarketplaceSkeletonCard key={key} />
                    </div>
                </CellMeasurer>
            );
        }

        return (
            <CellMeasurer cache={cache} index={index} key={key} parent={parent} rowIndex={index}>
                <div style={style}>
                    <MarketplaceCard to={`${marketplaceUrl}/${project.slug}`} data={project} />
                </div>
            </CellMeasurer>
        );
    };

    useEffect(() => {
        if (containerRef.current) {
            const width = containerRef.current.offsetWidth;
            calculateColumnCount(width);
        }
    }, [calculateColumnCount]);

    return (
        <div>
            <WindowScroller scrollElement={scrollElement}>
                {({ isScrolling, registerChild, scrollTop }) => (
                    <div ref={(el) => registerChild(el as ReactNode)}>
                        <AutoSizer onResize={onResize} disableHeight>
                            {({ width }) => {
                                calculateColumnCount(width);

                                return (
                                    <Masonry
                                        autoHeight
                                        cellCount={cellCount}
                                        cellMeasurerCache={cache}
                                        overscanByPixels={350}
                                        cellRenderer={cellRenderer}
                                        height={window.innerHeight}
                                        ref={masonryRef}
                                        isScrolling={isScrolling}
                                        scrollTop={scrollTop}
                                        width={width}
                                        cellPositioner={cellPositioner}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </div>
                )}
            </WindowScroller>
        </div>
    );
};

export default VirtualizedMarketplace;
