import styled from "styled-components";
import paperwrap from "../../assets/images/home-paperwrap.svg";
import WhyUsBackground from "../../assets/images/landingDesktop.webp";

interface StyledPaperWrapProps {
    background: string;
    marginTop: string;
    height: string;
}

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 550.06px);
    grid-gap: 50px;
    justify-content: center;
    margin: 20px 0;
    padding: 0 20px 40px 20px;

    @media (max-width: 1830px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }

    @media (max-width: 1070px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
`;

export const StyledSpace = styled.div`
    width: 450px;
    height: 360px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px;
    overflow: hidden;

    @media (max-width: 535px) {
        width: 100%;
    }
`;

export const StyledCard = styled.div`
    width: 440px;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(269.7deg, #316c2d 10.81%, #1c5718 74.35%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;
    align-items: flex-start;

    @media (max-width: 535px) {
        width: 100%;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    overflow: hidden;
`;

export const StyledImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 60%;
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60%;
        background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
        z-index: 1;
    }
`;

export const StyledImage = styled.img`
    width: 100%;
    height: auto;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: block;
    position: relative;
    z-index: 1;
`;

export const ImageWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledImageSlider = styled.img`
    width: auto;
    height: 100%;
    border-radius: 8px;
    pointer-events: none;
`;

export const StyledWhyUsBackground = styled.section`
    position: relative;
    background: linear-gradient(109.86deg, #234d20 1.5%, rgba(16, 74, 22, 0.5) 25.58%, rgba(40, 167, 69, 0.54) 97.11%),
        url(${WhyUsBackground});
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 35%;
        background: linear-gradient(360deg, #193b17 8.64%, rgba(20, 53, 18, 0.55) 45.68%, rgba(15, 47, 13, 0) 76.43%);
        z-index: 1;
    }

    @media (max-width: 768px) {
        height: auto;
    }

    @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
        height: auto;
        padding-bottom: 100px;
        padding-top: 50px;
    }
`;

export const StyledPaperWrap = styled.div<StyledPaperWrapProps>`
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    margin-top: ${(props) => props.marginTop || "-170px"};
    margin-bottom: 100px;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-image: url(${(props) => props.background});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: ${(props) => props.height || "300px"};
    }

    @media (max-width: 768px) {
        &::after {
            background-size: cover;
        }
    }
`;

export const StyledDiv = styled.div`
    padding: 0;

    @media (max-width: 1950px) {
        padding: 20px;
    }

    @media (max-width: 520px) {
        padding: 0;
    }
`;

export const OffersSection = styled.div`
    max-height: fit-content;
    overflow: hidden;
    height: auto;
    background-image: url("${(props) => props.theme.Triangles}"),
        linear-gradient(148.11deg, #336c2f 11.1%, #266921 90.91%);
    background-repeat: repeat-y;
    background-size: 100%;
    padding: 200px 5px 140px 5px;
    position: relative;

    @media (max-width: 768px) {
        padding-top: 120px;
    }

    &::after {
        content: "";
        position: absolute;
        background-image: url(${paperwrap});
        background-size: cover;
        background-position: bottom;
        top: -160px;
        left: 0;
        width: 100%;
        height: 300px;
        display: block;
    }
`;

export const OffersAnchor = styled.div`
    position: absolute;
    top: 1450px;

    @media (max-width: 900px) {
        position: absolute;
        top: 1200px;
    }

    @media (max-width: 600px) {
        position: absolute;
        top: 1000px;
    }
`;
