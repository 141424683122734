import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentScope } from "@sentry/browser";
import { AdminTypes } from ".";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { transformError } from "../../shared/helpers/errors";
import { Voucher } from "../Server/types";
import { AdminSearchResponse, CreateNewVoucherPayload } from "./types";

export const adminApi = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/admin`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            const scope = getCurrentScope();
            if (scope) {
                scope.setTag("transaction_id", transactionId);
            }
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),

    endpoints: (builder) => ({
        search: builder.mutation<AdminTypes.AdminSearchResponse, AdminTypes.AdminSearchOptions>({
            query: ({ q }) => {
                return {
                    url: "/search",
                    method: "GET",
                    credentials: "include",
                    params: { q },
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformResponse: async (response: AdminTypes.AdminSearchResponse) => {
                const transformedResponse = await transformToStruct(response, AdminSearchResponse, "/search");

                return transformedResponse;
            },
            transformErrorResponse: transformError,
        }),
        generateVoucher: builder.mutation<Voucher, CreateNewVoucherPayload>({
            query: (payload) => {
                return {
                    url: "/voucher/generate",
                    method: "POST",
                    credentials: "include",
                    body: payload,
                    validateStatus: (response) => response.status === HttpStatus.Created,
                };
            },
            transformErrorResponse: transformError,
        }),
    }),
});

export const { useSearchMutation, useGenerateVoucherMutation } = adminApi;
