import { Editor } from "@monaco-editor/react";
import { Button, Label, Paragraph, Select, Space } from "csrvui";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { useGetProductsQuery } from "../../../Billings/api";
import useClusters from "../../../Server/hooks/useClusters";
import { FormVoucherTarget } from "../../../Server/types";
import { VoucherTargetEditorContainer } from "./styles";

interface Props {
    onUpdate: (data: FormVoucherTarget) => void;
    values: FormVoucherTarget;
}

const VoucherTargetsEditor = ({ onUpdate, values }: Props) => {
    const { data: products, isLoading } = useGetProductsQuery();
    const [selectedProduct, setSelectedProduct] = useState<string>("*");
    const { getProductClusters } = useClusters();
    const { t } = useTranslation();
    const [showParameters, setShowParameters] = useState(false);
    const [jsonError, setJsonError] = useState(false);
    const theme = useTheme();
    const selectedProductData = selectedProduct !== "*" ? products?.find((p) => p.id === selectedProduct) : undefined;
    const billingPeriodsSet = new Set(selectedProductData?.prices.map((price) => price.period) || []);
    const billingPeriods = Array.from(billingPeriodsSet).map((p) => ({ value: p, name: p }));

    const availableClusters = useMemo(
        () => (selectedProductData ? getProductClusters(selectedProductData) : []),
        [selectedProductData, getProductClusters]
    );

    return (
        <VoucherTargetEditorContainer isLoading={isLoading}>
            <Space $fullWidth $padding={0} $align="start" $oppositeAlign="end">
                <Label style={{ fontWeight: "normal" }} htmlFor="product">
                    {t("create_new_voucher.fields.product")}
                </Label>
                <Select
                    id="product"
                    $fullWidth
                    disabled={isLoading}
                    onChange={(id) => {
                        setSelectedProduct(id);
                        onUpdate({
                            ...values,
                            product_id: id,
                            billing_period: "",
                            cluster_id: "",
                            user_parameters: {},
                        });
                    }}
                    options={[
                        ...(products ?? []).map((p) => ({ value: p.id, name: p.id })),
                        {
                            value: "*",
                            name: t("create_new_voucher.fields.all"),
                        },
                    ]}
                    defaultValue={selectedProduct}
                />
            </Space>
            <Space $fullWidth $padding={0}>
                <Label htmlFor="billing-period" style={{ fontWeight: "normal" }}>
                    {t("create_new_voucher.fields.billing_period")}
                </Label>
                <Select
                    onChange={(period) => {
                        onUpdate({
                            ...values,
                            billing_period: period,
                        });
                    }}
                    id="billing-period"
                    value={values.billing_period}
                    disabled={!selectedProduct}
                    $fullWidth
                    key={selectedProduct}
                    defaultValue=""
                    options={[
                        ...billingPeriods,
                        {
                            value: "",
                            name: t("create_new_voucher.fields.all"),
                        },
                    ]}
                />
            </Space>
            <Space $fullWidth $padding={0}>
                <Label style={{ fontWeight: "normal" }} htmlFor="cluster">
                    {t("create_new_voucher.fields.cluster")}
                </Label>
                <Select
                    id="cluster"
                    onChange={(cluster) => {
                        onUpdate({
                            ...values,
                            cluster_id: cluster,
                        });
                    }}
                    disabled={!selectedProduct}
                    key={selectedProduct}
                    value={values.cluster_id}
                    $fullWidth
                    defaultValue=""
                    options={[
                        ...availableClusters.map((p) => ({ value: p.zone_name, name: p.id })),
                        {
                            value: "",
                            name: t("create_new_voucher.fields.all"),
                        },
                    ]}
                />
            </Space>
            <Space $padding={0} $align="end" $oppositeAlign="end">
                <Button
                    type="button"
                    onClick={() => setShowParameters(true)}
                    style={{ minHeight: "46px" }}
                    $variant="light"
                >
                    {t("create_new_voucher.fields.parameters")}
                </Button>

                <Modal
                    open={showParameters}
                    onClose={() => {
                        setShowParameters(false);
                        setJsonError(false);
                    }}
                    center
                >
                    <Space style={{ width: "50vw", maxWidth: "900px" }} $padding="0 40px 20px 0">
                        <Paragraph $level={3}>{t("create_new_voucher.edit_parameters")}</Paragraph>
                    </Space>
                    {jsonError && (
                        <Paragraph
                            style={{
                                color: theme.colors.secondaryText,
                                backgroundColor: theme.colors.danger,
                                padding: "10px",
                                marginBottom: "20px",
                            }}
                        >
                            {t("create_new_voucher.validation.invalid_json")}
                        </Paragraph>
                    )}
                    <Editor
                        height="50vh"
                        defaultLanguage="json"
                        defaultValue={JSON.stringify(values.user_parameters)}
                        onChange={(value) => {
                            try {
                                setJsonError(false);
                                const parsedValue = JSON.parse(value || "{}");
                                onUpdate({
                                    ...values,
                                    user_parameters: parsedValue,
                                });
                            } catch (error) {
                                setJsonError(true);
                            }
                        }}
                    />
                </Modal>
            </Space>
        </VoucherTargetEditorContainer>
    );
};

export default VoucherTargetsEditor;
